import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import { StackableModalProps } from '@/Components/RootModal';
import { Client } from '@/Models/Client';
import { CreateUpdateClientModal } from './CreateUpdateClientModal';
import { ClientFormValues } from '@/Features/Clients';
import history from '@/Modules/History';
import { inUserZone, toISODate } from '@/Services/Date';
import { Consultation } from '@/Models/Consultation';
import CustomerSupport from '@/Services/CustomerSupport';
import queryClient from '@/Modules/QueryClient';

interface Props extends StackableModalProps {
    onCreate?(client: Client): void;
    consultation?: Consultation;
    navigateOnSuccess?: boolean;
    closeStackOnCreate?: boolean;
}

const Component: FunctionComponent<Props> = (props) => {
    const { clientStore, currentUserStore } = RootStore.stores;

    const mapPropsToValues = (): ClientFormValues => {
        return {
            firstName: props.consultation?.firstName ?? '',
            lastName: props.consultation?.lastName ?? '',
            email: props.consultation?.email ?? '',
            mobilePhone: props.consultation?.mobilePhone ?? '',
            signedUpAt: inUserZone()?.toJSDate(),
            sendInformationRequest: false,
            informationRequestMessage:
                currentUserStore.organisation.defaultClientInviteMessage ?? '',
        };
    };

    const handleSave = async (values: ClientFormValues) => {
        const client = await clientStore.store({
            ...values,
            dob: toISODate(values.dob),
            signedUpAt: toISODate(values.signedUpAt),
        });

        await queryClient.invalidateQueries(['clients', 'roster']);
        await queryClient.invalidateQueries(['clients', 'counts']);

        if (typeof props.onCreate === 'function') {
            props.onCreate(client);
        }

        CustomerSupport.trackEvent('Client Created Manual');

        if (props.navigateOnSuccess) {
            history.push(client.url);
        }
    };

    return (
        <CreateUpdateClientModal
            isCreating
            stackProps={props.stackProps}
            closeStackOnCreate={props.closeStackOnCreate}
            mapPropsToValues={mapPropsToValues}
            onSave={handleSave}
        />
    );
};

export const CreateClientModal = observer(Component);
