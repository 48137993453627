import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { OptionTypeBase } from 'react-select/src/types';
import { useField, useFormikContext } from 'formik';
import FormSelectField, { FormSelectFieldProps } from '../FormSelectField';
import RootStore from '../../Stores/RootStore';
import { Client } from '../../Models/Client';
import MembershipSubscriptionTransport from '@/Services/Transport/MembershipSubscriptionTransport';
import { MembershipSubscription } from '@/Models/MembershipSubscription';
import { mergeFilters } from '@/Services/ApiFilters';

interface MembershipSubscriptionFilters {
    'eligible-for'?: string;
    'ending-after'?: string;
    status?: 'active' | 'usable';
    withAttributes?: string[];
}

interface Props extends FormSelectFieldProps {
    client: Client;
    filters?: MembershipSubscriptionFilters;
    explicitOptions?: MembershipSubscription[];
}

const CustomOption: FunctionComponent = (option: OptionTypeBase) => {
    const { membershipSubscriptionStore } = RootStore.stores;
    const membershipSubscription = membershipSubscriptionStore.find(
        option.value
    );

    return (
        <div>
            <span className="mr-2 inline-block">{option.label}</span>
            <span className="my-2 inline-block h-6 whitespace-nowrap rounded-full border border-gray-200 px-2 pt-1 text-sm leading-3">
                {membershipSubscription?.membership?.enrolmentLimitEnabled
                    ? membershipSubscription.remainingCredits
                    : '∞'}{' '}
                left
            </span>
        </div>
    );
};

const FormMembershipSubscriptionSelectField: FunctionComponent<Props> = ({
    client,
    filters,
    explicitOptions,
    ...props
}) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props.name);

    const { data, isLoading } = useQuery(
        ['membershipSubscriptions', 'list', { client: client.eid, ...filters }],
        async () => {
            const { data } = await MembershipSubscriptionTransport.list(
                mergeFilters(
                    {
                        client: client.eid,
                        include: ['membership'],
                    },
                    filters
                )
            );
            return data.data;
        },
        { cacheTime: 0, enabled: typeof explicitOptions === 'undefined' }
    );

    const options = useMemo(() => {
        const availableOptions = explicitOptions ? explicitOptions : data;

        return (availableOptions ?? []).map((sub: MembershipSubscription) => ({
            value: sub.uuid,
            label: sub.membership?.name ?? 'Unknown membership',
        }));
    }, [data, explicitOptions]);

    useEffect(() => {
        if (!options.length) {
            setFieldValue(props.name, undefined);
        } else if (options.length && !field.value) {
            setFieldValue(props.name, options[0].value);
        }
    }, [options]);

    return (
        <FormSelectField
            options={options}
            {...props}
            fieldProps={{
                formatOptionLabel: CustomOption,
                isDisabled: isLoading || options.length === 0,
            }}
        />
    );
};

export default FormMembershipSubscriptionSelectField;
