import React, { FunctionComponent, useMemo } from 'react';
import { ClassDate } from '../../../Models/ClassDate';
import { observer } from 'mobx-react';
import ImageClientGroup from '../../ImageClientGroup';
import { countInflect } from '../../../Services/String';
import classNames from 'classnames';
import NoticeTag from '@/Components/NoticeTag';
import { Enrolment } from '@/Models/Enrolment';
import { ClassDateEnrolOptionsButton } from '@/Features/ClassDates';
import RootStore from '@/Stores/RootStore';

interface Props {
    classDate: ClassDate;
}

const ClassDateEnrolmentSummary: FunctionComponent<Props> = ({ classDate }) => {
    const { organisation } = RootStore.stores.currentUserStore;

    const count = 5;

    const enrolled = useMemo(() => {
        return classDate.enrolments
            .slice(0, count)
            .filter((enrolment: Enrolment) => !!enrolment.client)
            .map((enrolment: Enrolment) => enrolment.client);
    }, [
        classDate,
        classDate.enrolments.length,
        classDate.enrolments,
        classDate.totalAttending,
    ]);

    return (
        <div className="mt-3 flex rounded border border-gray-200 p-3">
            <div
                className={classNames('flex flex-1 items-center', {
                    'justify-between': classDate.isFull,
                })}
            >
                {enrolled.length > 0 && <ImageClientGroup clients={enrolled} />}

                {classDate.hasAvailableSpaces && (
                    <div
                        className={classNames(
                            'flex rounded-full border border-gray-200 text-gray-500',
                            { 'ml-2': classDate.totalAttending > 0 }
                        )}
                    >
                        {classDate.totalAttending > count && (
                            <span className="flex items-center border-r border-gray-200 px-2 py-1">
                                +{classDate.totalAttending - count}
                            </span>
                        )}
                        <span className="px-2 py-1">
                            {!classDate.hasLimitedSpaces && '∞ spaces'}
                            {classDate.hasLimitedSpaces &&
                                countInflect(
                                    classDate.availableSpaces,
                                    'space',
                                    'spaces'
                                )}
                        </span>
                    </div>
                )}
                {!classDate.hasAvailableSpaces && (
                    <NoticeTag type="warning" className="mr-3">
                        {organisation.waitlistEnabled ? 'Waitlisted' : 'Full'}
                    </NoticeTag>
                )}
            </div>

            <ClassDateEnrolOptionsButton classDate={classDate} />
        </div>
    );
};

export default observer(ClassDateEnrolmentSummary);
