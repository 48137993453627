import { ClientAvatar } from '@/Features/Clients';
import { Client } from '@/Models/Client';
import history from '@/Modules/History';
import RootStore from '@/Stores/RootStore';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { ComponentPropsWithoutRef, FunctionComponent } from 'react';
import useAuth from '../../../Hooks/useAuth';
import { initialed } from '@/Services/String';
import { ClientModel } from '@/Features/Clients/Types';
import { clientUrl } from '@/Features/Clients/Utils';

interface Props extends ComponentPropsWithoutRef<'div'> {
    client: Client | ClientModel;
    nameDisplay?: 'initialed' | 'full';
    size?: 'small' | 'medium';
    suffix?: string;
    link?: boolean;
    linkTab?: string;
}

const ClientCard: FunctionComponent<Props> = ({
    link = false,
    nameDisplay = 'initialed',
    client,
    suffix,
    size = 'small',
    linkTab,
    ...props
}) => {
    const { uiStateStore } = RootStore.stores;
    const { hasRole } = useAuth();

    const handleClick = () => {
        if (!link || !hasRole('Owner')) {
            return;
        }

        uiStateStore.closeStack();
        history.push(clientUrl(client, linkTab));
    };

    return (
        <div
            className={classNames('flex items-center', {
                'hover:cursor-pointer': link,
            })}
            onClick={handleClick}
            {...props}
        >
            <ClientAvatar
                withLink={link && hasRole('Owner')}
                client={client}
                imageClassName="w-9 h-9"
            />

            <div className="ml-2 min-w-0">
                <p
                    className={classNames(
                        'm-0 truncate text-sm font-bold transition-colors lg:text-base',
                        { 'text-link': link }
                    )}
                >
                    {nameDisplay === 'initialed'
                        ? initialed(client.firstName, client.lastName)
                        : `${client.firstName} ${client.lastName}`}
                </p>
                {suffix && (
                    <div className="text-sm text-gray-400">{suffix}</div>
                )}
            </div>
        </div>
    );
};

export default observer(ClientCard);
