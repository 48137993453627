import React, { FunctionComponent, useEffect, useState } from 'react';
import parse from 'date-fns/parse';
import { default as isValidDate } from 'date-fns/isValid';
import { useField, useFormikContext } from 'formik';
import { FormFieldElementProps } from '../FormFieldElements';
import TextInputField from '../TextInputField';
import FormTextField from '../../Domain/Form/FormTextField';
import { TextButton } from '../Button';
import SelectField from '@/Components/SelectField';
import { monthOptions as defaultMonthOptions } from '@/Config/options';

interface Props extends FormFieldElementProps {
    withToday?: boolean;
    isDisabled?: boolean;
}

const FormDateFieldGroup: FunctionComponent<Props> = ({
    withToday,
    isDisabled,
    ...props
}) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const [field] = useField(props);
    const [dateSegments, setDateSegments] = useState(
        getDateSegments(field.value)
    );
    const monthOptions = defaultMonthOptions.map((opt) => ({
        value: opt.value,
        label: opt.label.substr(0, 3),
    }));

    useEffect(() => {
        setFieldValue(field.name, undefined);

        if (
            !!dateSegments.d &&
            !!dateSegments.m &&
            dateSegments.y.length === 4
        ) {
            setFieldTouched(field.name, true);

            const date = parse(
                `${dateSegments.y}-${dateSegments.m}-${dateSegments.d}`,
                'yyyy-M-d',
                new Date()
            );

            if (isValidDate(date)) {
                setFieldValue(field.name, date);
            } else {
                setFieldValue(field.name, undefined);
            }
        }
    }, [dateSegments]);

    function getDateSegments(date?: Date) {
        date = date ?? field?.value;

        if (date instanceof Date) {
            return {
                d: String(date.getDate()),
                m: date.getMonth() + 1,
                y: String(date.getFullYear()),
            };
        }
        return { d: '', m: '', y: '' };
    }

    function handleDobChange(segment: 'd' | 'm' | 'y', value: string) {
        if (segment === 'd') {
            setDateSegments({
                ...dateSegments,
                d: value,
            });
        }
        if (segment === 'm') {
            setDateSegments({
                ...dateSegments,
                m: value,
            });
        }
        if (segment === 'y') {
            setDateSegments({
                ...dateSegments,
                y: value.length <= 4 ? value : dateSegments.y,
            });
        }
    }

    function setToday() {
        setDateSegments(getDateSegments(new Date()));
    }

    return (
        <FormTextField
            {...props}
            input={(fieldProps) => (
                <div className="flex items-center pt-0.5">
                    <TextInputField
                        type="number"
                        min={1}
                        max={31}
                        value={dateSegments.d}
                        onChange={(ev) => handleDobChange('d', ev.target.value)}
                        placeholder="DD"
                        className="mr-2"
                        style={{ width: 70 }}
                        isDisabled={isDisabled}
                    />
                    <SelectField
                        options={monthOptions}
                        value={dateSegments.m}
                        // @ts-ignore
                        onChange={(selected) =>
                            handleDobChange('m', selected?.value)
                        }
                        className="mr-2 h-12 min-w-[110px]"
                        isDisabled={isDisabled}
                    />
                    <TextInputField
                        type="number"
                        min={1}
                        max={9999}
                        value={dateSegments.y}
                        onChange={(ev) => handleDobChange('y', ev.target.value)}
                        placeholder="YYYY"
                        className="mr-2"
                        style={{ width: 90 }}
                        isDisabled={isDisabled}
                    />
                    {withToday && (
                        <TextButton type="button" onClick={setToday}>
                            Today
                        </TextButton>
                    )}
                </div>
            )}
        />
    );
};

export default FormDateFieldGroup;
