import React, { FunctionComponent } from 'react';
import { ClassDate } from '@/Models/ClassDate';
import { observer } from 'mobx-react';
import { Tooltip } from '@/Components/Tooltip';
import { Icon } from '@mdi/react';
import { mdiAccountClock, mdiAccountMultipleCheck } from '@mdi/js';
import RootStore from '@/Stores/RootStore';

interface Props {
    classDate: ClassDate;
}

const Component: FunctionComponent<Props> = ({ classDate }) => {
    const { organisation } = RootStore.stores.currentUserStore;

    return (
        <Tooltip
            label={`${classDate.totalAttending} attending${
                organisation.waitlistEnabled
                    ? ` · ${classDate.totalOnWaitlist} on waitlist`
                    : ''
            }`}
        >
            <div className="flex items-center divide-x divide-white divide-opacity-50 rounded-md border border-white border-opacity-50">
                <div className="flex px-[7px] py-1">
                    <Icon
                        path={mdiAccountMultipleCheck}
                        className="h-5 w-5 fill-current text-white"
                    />
                    <span className="ml-2 font-bold text-white">
                        {classDate.totalAttending}
                    </span>
                </div>
                {organisation.waitlistEnabled && (
                    <div className="flex px-[7px] py-1">
                        <Icon
                            path={mdiAccountClock}
                            className="h-[18px] w-[18px] fill-current text-white"
                        />
                        <span className="ml-1.5 font-bold text-white">
                            {classDate.totalOnWaitlist}
                        </span>
                    </div>
                )}
            </div>
        </Tooltip>
    );
};

export const ClassDateEnrolmentCountPill = observer(Component);
