import React, { FunctionComponent } from 'react';
import RootStore from '@/Stores/RootStore';
import { ClassDate } from '@/Models/ClassDate';
import { observer } from 'mobx-react';
import { Icon } from '@mdi/react';
import { mdiAccountCheck } from '@mdi/js';
import { Button, ClearButton } from '@/Components/Button';

interface Props {
    classDate: ClassDate;
    variant?: 'default' | 'menu';
}

const Component: FunctionComponent<Props> = ({
    classDate,
    variant = 'default',
}) => {
    const handleClick = () => {
        RootStore.stores.uiStateStore.pushModal('CLASS_DATE_ENROLMENT_CREATE', {
            classDate,
        });
    };

    if (variant === 'menu') {
        return (
            <ClearButton
                icon={
                    <Icon
                        path={mdiAccountCheck}
                        size={0.8}
                        className="-ml-0.5 mr-1.5 fill-current text-icon"
                    />
                }
                onClick={handleClick}
            >
                <span>Enrol on class</span>
            </ClearButton>
        );
    }

    return <Button icon="AccountPlus" onClick={handleClick} />;
};

export const ClassDateEnrolButton = observer(Component);
