import { applySnapshot, flow, getSnapshot, types } from 'mobx-state-tree';
import { Agreement } from '../Models/Agreement';
import AgreementTransport from '@/Services/Transport/AgreementTransport';

function formatIdentifier(identifier) {
    if (!identifier.startsWith('agreement-')) {
        identifier = `agreement-${identifier}`;
    }

    return identifier;
}

export const AgreementStore = types
    .model('AgreementStore', {
        agreements: types.map(Agreement),
    })

    .views((self) => ({
        find(identifier) {
            return self.agreements.get(formatIdentifier(identifier));
        },

        /**
         * Get the user's available agreements
         * @returns {*}
         */
        get available() {
            return Array.from(self.agreements.values()).filter(
                (agreement) => !agreement.isDeleted && !agreement.isArchived
            );
        },

        /**
         * Determine if the user has any available agreements
         * @returns {boolean}
         */
        get hasAvailable() {
            return !!self.available.length;
        },

        /**
         *
         * @param name
         * @param exclude
         * @returns {boolean}
         */
        nameIsUnique(name, exclude = null) {
            return !self.available.find((agreement) => {
                let excludeClause = !!exclude
                    ? agreement.eid !== exclude
                    : true;
                return (
                    agreement.name.toLowerCase() === name.toLowerCase() &&
                    excludeClause
                );
            });
        },
    }))

    .actions((self) => ({
        list: flow(function* list(params = {}, withMeta = false) {
            const { data } = yield AgreementTransport.list(params);
            const items = data.data.map((agreement) =>
                self.createOrUpdate(agreement)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        fetch: flow(function* fetch(eid, params = {}) {
            const { data } = yield AgreementTransport.fetch(eid, params);
            return self.createOrUpdate(data.data);
        }),

        store: flow(function* store(agreementData) {
            const { data } = yield AgreementTransport.store(agreementData);
            self.createOrUpdate(data.data);

            return self.find(data.data.identifier);
        }),

        destroy: flow(function* destroy(identifier) {
            const { data } = yield AgreementTransport.destroy(identifier);
            return self.createOrUpdate(data.data);
        }),

        init(agreements) {
            agreements.forEach((agreement) => {
                self.createOrUpdate(agreement);
            });
        },

        createOrUpdate(agreement) {
            if (self.agreements.has(agreement.identifier)) {
                const existing = self.agreements.get(agreement.identifier);
                applySnapshot(existing, {
                    ...getSnapshot(existing),
                    ...agreement,
                });
            } else {
                self.agreements.set(agreement.identifier, agreement);
            }

            return self.agreements.get(agreement.identifier);
        },

        /**
         * Add the membership to the store, and return the identifier
         * @returns {*}
         * @param agreement
         */
        processAsNested(agreement) {
            if (!!agreement?.identifier) {
                self.createOrUpdate(agreement);
                return agreement.identifier;
            }

            return agreement;
        },
    }));
