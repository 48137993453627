import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { StackableModalProps } from '../../../Components/RootModal';
import Modal from '../../../Components/Modal';
import { observer } from 'mobx-react';
import { FormikProps, withFormik } from 'formik';
import FormClassPassPackageSelectField from '../../../Components/FormClassPassPackageSelectField';
import FormField from '../../../Components/FormField';
import { PrimaryButton } from '../../../Components/Button';
import RootStore from '../../../Stores/RootStore';
import { Package } from '../../../Models/Package';
import { Client } from '../../../Models/Client';
import Notification from '../../../Services/Notification';
import * as Yup from 'yup';
import { messages } from '../../../Config/validation';
import SelectableOptionList from '../../../Components/SelectableOptionList';
import FormRadioField from '../../../Components/FormRadioField';
import { currencyDisplay } from '../../../Services/Currency';
import FormPaymentMethodSelectField from '../../../Components/FormPaymentMethodSelectField';
import Notice from '../../../Components/Notice';
import FormDateField from '../../../Components/FormDateField';
import FormCheckboxField from '../../../Components/FormCheckboxField';
import { inUserZone } from '../../../Services/Date';
import PackageTransport from '../../../Services/Transport/PackageTransport';
import StripeConnectBanner from '../../../Components/StripeConnect/Banner';
import StriivePaymentsLinkWarning from '../../../Components/StriivePaymentsLinkWarning';
import StriivePaymentsMicroIcon from '../../../Components/StriivePayments/MicroIcon';
import FormClientSelectField from '../../../Components/FormClientSelectField';
import queryClient from '@/Modules/QueryClient';
import history from '../../../Modules/History';
import { ClassPass } from '../../../Models/ClassPass';
import { AxiosError } from 'axios';
import CustomerSupport from '@/Services/CustomerSupport';

const validationSchema = Yup.object().shape({
    classPass: Yup.string().required(messages.required),
    client: Yup.string().required(messages.required),
    method: Yup.string().required(messages.required),
    paymentMethod: Yup.string().when('method', {
        is: 'immediate',
        then: Yup.string().required(messages.required),
    }),
    paymentDate: Yup.date().when('method', {
        is: 'immediate',
        then: Yup.date().required(messages.required),
    }),
});

interface FormValues {
    classPass: string;
    client: string;
    method?: 'immediate' | 'link';
    paymentMethod: string;
    paymentDate?: Date;
    paymentReceipt: boolean;
}

interface Props extends StackableModalProps {
    client?: Client;
    onSuccess?(classPass: ClassPass): void;
    navigateOnSuccess?: boolean;
}

const Component: FunctionComponent<Props & FormikProps<FormValues>> = (
    props
) => {
    const { packageStore, clientStore, currentUserStore } = RootStore.stores;
    const { values, isSubmitting, handleSubmit, setFieldValue } = props;
    const { canReceivePayment } = currentUserStore.organisation;
    const [selectedPackage, setSelectedPackage] = useState<
        Package | undefined
    >();
    const [selectedClient, setSelectedClient] = useState<Client | undefined>(
        props.client
    );

    useEffect(() => {
        if (packageStore.classPasses.length) {
            setFieldValue('classPass', packageStore.classPasses[0].uuid);
        }
    }, []);

    useEffect(() => {
        if (values.classPass) {
            const selected = packageStore.find(values.classPass);

            if (selected) {
                setSelectedPackage(selected);
            }
        }
    }, [values.classPass]);

    useEffect(() => {
        if (values.client) {
            const selected = clientStore.find(values.client);

            if (selected) {
                setSelectedClient(selected);
            }
        }
    }, [values.client]);

    const title = useMemo(() => {
        return selectedClient
            ? `Sell pass to ${selectedClient.firstName}`
            : 'Sell pass';
    }, [selectedClient]);

    return (
        <Modal
            isOpen
            title={title}
            onSubmit={handleSubmit}
            rightAction={() => (
                <PrimaryButton type="submit" isLoading={isSubmitting}>
                    {values.method === 'link' ? 'Send' : 'Assign'}
                </PrimaryButton>
            )}
            {...props}
        >
            <StripeConnectBanner />

            <section className="dialog__panel">
                <header className="mb-8">
                    <h2 className="ui-action-bar__title mb-1">Sale details</h2>
                    <p>
                        Configure your available class passes as{' '}
                        <NavLink to="/class-passes/packages">packages</NavLink>,
                        then use this screen to manually assign them to clients.
                        Passes can also be purchased via your client area
                    </p>
                </header>

                <FormField.Row>
                    <FormClassPassPackageSelectField
                        isRequired
                        type="class"
                        name="classPass"
                        label="Package"
                        packages={packageStore.classPasses}
                    />
                    <FormField />
                </FormField.Row>

                {!props.client && (
                    <FormField.Row className="m-0">
                        <FormClientSelectField
                            name="client"
                            label="Client"
                            isRequired
                            isCreatable
                        />
                        <FormField />
                    </FormField.Row>
                )}
            </section>

            {selectedPackage && selectedClient && (
                <>
                    <section className="dialog__panel">
                        <h2 className="ui-action-bar__title mb-2">Method</h2>

                        <FormField className="m-0">
                            <SelectableOptionList horizontal>
                                <FormRadioField
                                    name="method"
                                    value="immediate"
                                    label={
                                        <span>
                                            <strong>Record payment now</strong>
                                            <br />
                                            <span className="font-normal">
                                                Assign this class pass to{' '}
                                                {selectedClient.firstName} now
                                                and add a manual record of their
                                                payment.
                                            </span>
                                        </span>
                                    }
                                />
                                <FormRadioField
                                    name="method"
                                    value="link"
                                    label={
                                        <span>
                                            <strong className="relative">
                                                Send purchase link{' '}
                                                <StriivePaymentsMicroIcon />
                                            </strong>
                                            <br />
                                            <span className="font-normal">
                                                Email {selectedClient.firstName}{' '}
                                                a link to purchase this class
                                                pass online using Striive
                                                Payments.
                                            </span>
                                        </span>
                                    }
                                    isDisabled={
                                        !selectedClient.email ||
                                        !canReceivePayment
                                    }
                                />
                            </SelectableOptionList>

                            <StriivePaymentsLinkWarning
                                client={selectedClient}
                            />
                        </FormField>
                    </section>

                    {selectedPackage && values.method === 'immediate' && (
                        <>
                            <fieldset className="dialog__panel">
                                <h2 className="ui-action-bar__title mb-2">
                                    Payment of{' '}
                                    {currencyDisplay(
                                        selectedPackage!.cost,
                                        selectedPackage.currency
                                    )}
                                </h2>

                                <FormField.Row className="m-0">
                                    <FormPaymentMethodSelectField
                                        name="paymentMethod"
                                        isRequired
                                    />
                                    <FormDateField
                                        name="paymentDate"
                                        label="Date"
                                        maxDate={new Date()}
                                        isRequired
                                    />
                                </FormField.Row>
                            </fieldset>

                            <fieldset className="dialog__panel">
                                <h2 className="ui-action-bar__title mb-2">
                                    Notifications
                                </h2>

                                <FormField className="m-0">
                                    <FormCheckboxField
                                        name="paymentReceipt"
                                        label={`Send ${selectedClient.firstName} a receipt including the class pass details and conditions.`}
                                        isDisabled={!selectedClient.email}
                                    />

                                    {!selectedClient.email && (
                                        <Notice type="warning" className="mt-2">
                                            {selectedClient.firstName} does not
                                            have an email address.
                                        </Notice>
                                    )}
                                </FormField>
                            </fieldset>
                        </>
                    )}
                </>
            )}
        </Modal>
    );
};

export const AssignClassPassModal = withFormik<Props, FormValues>({
    validationSchema,
    validateOnMount: true,
    mapPropsToValues: (props) => ({
        classPass: '',
        client: props.client?.eid ?? '',
        paymentMethod: '',
        paymentDate: inUserZone()?.toJSDate(),
        paymentReceipt: true,
    }),
    handleSubmit: async (values, { props, setErrors }) => {
        const { uiStateStore, classPassStore } = RootStore.stores;
        let message = 'Class pass added.';

        try {
            if (values.method === 'link') {
                await PackageTransport.sendPurchaseLink(
                    values.classPass,
                    values.client
                );
                message = 'Class pass link sent.';
            } else {
                const classPass = await classPassStore.store(values);
                await queryClient.invalidateQueries([
                    'classPasses',
                    'purchases',
                ]);
                await queryClient.invalidateQueries(['transactions']);

                CustomerSupport.trackEvent('Class Pass Package Assigned');

                if (typeof props.onSuccess === 'function') {
                    props.onSuccess(classPass);
                }

                if (props.navigateOnSuccess !== false) {
                    history.push(classPass.url);
                }
            }
        } catch (e) {
            console.log(e);
            if ((e as AxiosError).response?.status === 422) {
                setErrors((e as AxiosError).response?.data.errors);
            }

            return;
        }

        Notification.success(message);
        uiStateStore.popModal();
    },
})(observer(Component));
