import React, { FunctionComponent } from 'react';
import { ReactComponent as StriivePaymentsIcon } from '../../../../images/icon-striive-payments.svg';

interface Props {
    width?: number;
    height?: number;
    className?: string;
}

const StriivePaymentsMicroIcon: FunctionComponent<Props> = ({
    className = 'absolute left-full top-0.5 ml-2 w-4 h-4',
}) => <StriivePaymentsIcon className={className} />;

export default StriivePaymentsMicroIcon;
