import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { StackableModalProps } from '@/Components/RootModal';
import { Agreement } from '@/Models/Agreement';
import { CreateUpdateAgreementModal } from './CreateUpdateAgreementModal';
import { AgreementFormValues } from '@/Features/Agreements';

interface Props extends StackableModalProps {
    agreement: Agreement;
    onCreate?(agreement: Agreement): void;
}

const Component: FunctionComponent<Props> = (props) => {
    const mapPropsToValues = (): AgreementFormValues => {
        const { name, content } = props.agreement;

        return {
            name,
            content,
        };
    };

    const handleSave = async (values: AgreementFormValues) => {
        await props.agreement.update(values);
    };

    return (
        <CreateUpdateAgreementModal
            isCreating={false}
            stackProps={props.stackProps}
            mapPropsToValues={mapPropsToValues}
            onSave={handleSave}
        />
    );
};

export const UpdateAgreementModal = observer(Component);
