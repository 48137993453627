import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';
import { FormikProps, withFormik } from 'formik';
import { StackableModalProps } from '@/Components/RootModal';
import Notification from '@/Services/Notification';
import { PrimaryButton, TextButton } from '@/Components/Button';
import Modal from '@/Components/Modal';
import RootStore from '@/Stores/RootStore';
import FormField from '@/Components/FormField';
import FormTextField from '@/Domain/Form/FormTextField';
import { genderOptions } from '@/Config/options';
import FormSelectField from '@/Components/FormSelectField';
import FormDateFieldGroup from '@/Components/FormDateFieldGroup';
import FormAgreementSelectField from '@/Components/FormAgreementSelectField';
import FormCheckboxField from '@/Components/FormCheckboxField';
import { ClientFormValues } from '@/Features/Clients';
import FormCountrySelectField from '@/Components/FormCountrySelectField';
import queryClient from '@/Modules/QueryClient';
import { AxiosError } from 'axios';
import handleServerValidation from '@/Utilities/handleServerValidation';
import { queryKeys } from '@/Services/QueryKeys';
import Avatar from '@/Components/Avatar';
import ImageUploadZone, { UploadedFile } from '@/Components/ImageUploadZone';
import { Client } from '@/Models/Client';
import FormTimezoneSelectField from '@/Components/FormTimezoneSelectField';
import FormTextAreaField from '@/Components/FormTextAreaField';

interface Props extends StackableModalProps {
    isCreating: boolean;
    client?: Client;
    mapPropsToValues(): ClientFormValues;
    onSave(values: ClientFormValues): void;
    closeStackOnCreate?: boolean;
}

const Component: FunctionComponent<Props & FormikProps<ClientFormValues>> = (
    props
) => {
    const { uiStateStore, agreementStore, currentUserStore } = RootStore.stores;
    const { values, isCreating, handleSubmit, isSubmitting, setFieldValue } =
        props;
    const [previewUrl, setPreviewUrl] = useState<string | undefined>(
        props.client?.image?.urls.original
    );

    const handleUploaded = (file: UploadedFile, preview: string) => {
        Notification.success('Profile image uploaded. Please save to confirm.');

        setPreviewUrl(preview);
        props.setFieldValue('image', file);
    };

    return (
        <Modal
            stackProps={props.stackProps}
            title={isCreating ? 'Add client' : 'Update client details'}
            onSubmit={handleSubmit}
            leftAction={() => (
                <TextButton
                    type="button"
                    onClick={() => uiStateStore.popModal()}
                >
                    Cancel
                </TextButton>
            )}
            rightAction={() => (
                <PrimaryButton
                    type="submit"
                    isLoading={isSubmitting}
                    data-testid="save-client-button"
                >
                    Save
                </PrimaryButton>
            )}
            data-testid="create-update-client-modal"
        >
            <fieldset className="dialog__panel">
                <h2 className="ui-action-bar__title mb-2">Profile picture</h2>

                <div className="flex items-center gap-4">
                    {!!previewUrl && (
                        <Avatar
                            src={previewUrl}
                            alt={values.firstName}
                            className="h-[90px] w-[90px] min-w-[90px] object-cover"
                        />
                    )}
                    <ImageUploadZone
                        onUploaded={handleUploaded}
                        onPreview={setPreviewUrl}
                    />
                </div>
            </fieldset>

            <fieldset className="dialog__panel">
                <h2 className="ui-action-bar__title mb-2">Basic details</h2>

                <FormField.Row>
                    <FormTextField
                        name="firstName"
                        label="First name"
                        isRequired
                    />
                    <FormTextField
                        name="lastName"
                        label="Last name"
                        isRequired
                    />
                </FormField.Row>

                <FormField.Row>
                    <FormDateFieldGroup name="dob" label="Date of birth" />
                    <FormSelectField
                        name="gender"
                        label="Gender"
                        options={genderOptions}
                        fieldProps={{ isClearable: true }}
                    />
                </FormField.Row>

                <FormField.Row>
                    <FormTextField
                        name="email"
                        label="Email"
                        info="For sending schedules, receipts, etc."
                        isRequired
                    />
                    <FormTextField
                        name="mobilePhone"
                        label="Mobile phone"
                        info="For sending reminders, cancellations, etc."
                    />
                </FormField.Row>

                <FormField.Row className="!mb-0">
                    <FormDateFieldGroup
                        name="signedUpAt"
                        label="Client since"
                        className="!mb-0"
                    />
                    <FormField />
                </FormField.Row>
            </fieldset>

            {isCreating && (
                <fieldset className="dialog__panel">
                    <h2 className="ui-action-bar__title mb-2">
                        Additional information
                    </h2>

                    <div className="prose prose-sm mb-8">
                        <p>
                            You can ask {values.firstName || 'the client'} to
                            complete their client profile by sending an
                            information request. This will ask them to:
                        </p>

                        <ul>
                            <li>Complete a PAR-Q (if enabled)</li>
                            <li>Sign an agreement</li>
                            <li>
                                Create a client area and app password (if
                                enabled)
                            </li>
                        </ul>
                    </div>

                    <FormField>
                        <FormCheckboxField
                            name="sendInformationRequest"
                            label={`Send information request to ${
                                values.firstName || 'the client'
                            }`}
                        />

                        <FormField.Children
                            isHidden={!values.sendInformationRequest}
                        >
                            <FormField.Row>
                                <FormAgreementSelectField
                                    name="agreement"
                                    label="Agreement to sign"
                                    withCreateButton
                                    displayErrors="after-submit"
                                    fieldProps={{
                                        isDisabled:
                                            !values.sendInformationRequest,
                                    }}
                                />
                                <FormField />
                            </FormField.Row>

                            <FormTextAreaField
                                name="informationRequestMessage"
                                label="Include personal message"
                                isDisabled={!values.sendInformationRequest}
                            />
                        </FormField.Children>
                    </FormField>
                </fieldset>
            )}

            {!isCreating && (
                <fieldset className="dialog__panel">
                    <h2 className="ui-action-bar__title mb-2">Address</h2>

                    <FormField.Row>
                        <FormTextField name="address1" label="Address 1" />
                        <FormTextField name="address2" label="Address 2" />
                    </FormField.Row>
                    <FormField.Row>
                        <FormTextField name="city" label="City" />
                        <FormTextField name="postCode" label="Postcode / zip" />
                    </FormField.Row>
                    <FormField.Row>
                        <FormCountrySelectField
                            name="country"
                            label="Country"
                        />
                        <FormTimezoneSelectField
                            name="timezone"
                            label="Timezone"
                        />
                    </FormField.Row>
                </fieldset>
            )}
        </Modal>
    );
};

export const CreateUpdateClientModal = withFormik<Props, ClientFormValues>({
    mapPropsToValues: (props) => props.mapPropsToValues(),
    handleSubmit: async (values, { props, setErrors }) => {
        const { uiStateStore } = RootStore.stores;

        try {
            await props.onSave(values);
            await queryClient.invalidateQueries(queryKeys.clients.all);

            Notification.success(
                `Client ${props.isCreating ? 'added' : 'updated'}`
            );

            if (props.closeStackOnCreate) {
                uiStateStore.closeStack();
            } else {
                uiStateStore.popModal();
            }
        } catch (e) {
            console.log(e);
            handleServerValidation(e as AxiosError, setErrors);
        }
    },
})(observer(Component));
