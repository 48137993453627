import {
    applySnapshot,
    flow,
    getRoot,
    getSnapshot,
    types,
} from 'mobx-state-tree';
import ClientTransport from '../Services/Transport/ClientTransport';
import { Client } from '../Models/Client';
import FinanceTransport from '../Services/Transport/FinanceTransport';

function formatIdentifier(identifier) {
    if (identifier.substr(0, 6) !== 'client') {
        identifier = `client-${identifier}`;
    }

    return identifier;
}

export const ClientStore = types
    .model('ClientStore', {
        busy: false,
        clients: types.map(Client),
    })

    .volatile((self) => ({
        inFlight: new Set(),
    }))

    .views((self) => {
        const fetchClient = async (eid, params = {}) => {
            if (self.inFlight.has(eid) || self.clients.has(eid)) return;

            self.inFlight.add(eid);

            try {
                await self.fetch(eid, params);
            } catch (error) {
                console.log({ error });
            }

            self.inFlight.delete(eid);
        };

        return {
            find(identifier) {
                console.log(self.clients.get(formatIdentifier(identifier)));
                return self.clients.get(formatIdentifier(identifier));
            },

            findOrFetch(identifier, params = {}) {
                identifier = formatIdentifier(identifier);
                fetchClient(identifier, params);
                return self.clients.get(identifier);
            },

            get available() {
                return Array.from(self.clients.values())
                    .filter((client) => !client.isArchived)
                    .sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
            },

            get hasAvailable() {
                return self.available.length > 0;
            },
        };
    })

    .actions((self) => ({
        clearStore() {
            applySnapshot(self, {});
        },

        list: flow(function* list(params = {}, withMeta = false) {
            const { data } = yield ClientTransport.list(params);

            const items = data.data.map((client) =>
                self.createOrUpdate(client)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        listTopDebtors: flow(function* listTopDebtors(params = {}) {
            const { data } = yield FinanceTransport.listTopDebtors(params);
            const items = data.data.map((client) =>
                self.createOrUpdate(client)
            );

            return { data: items, meta: data.meta };
        }),

        fetch: flow(function* fetch(eid, params = {}) {
            const { data } = yield ClientTransport.fetch(eid, params);
            return self.createOrUpdate(data.data);
        }),

        store: flow(function* store(clientData) {
            const { data } = yield ClientTransport.store(clientData);
            self.createFromVersion(data.data);

            return self.find(data.data.identifier);
        }),

        createOrUpdate(clientData) {
            let client = self.preProcessClient(clientData);
            client = self.processNestedResources(clientData);

            if (self.clients.has(client.identifier)) {
                const existing = self.clients.get(client.identifier);
                applySnapshot(existing, {
                    ...getSnapshot(existing),
                    ...client,
                });
            } else {
                self.clients.set(client.identifier, client);
            }

            return self.clients.get(client.identifier);
        },

        preProcessClient(clientData) {
            if (
                clientData.resourceVersion === 1 &&
                Array.isArray(clientData.dataReviews?.data)
            ) {
                clientData.dataReviews = clientData.dataReviews.data;
            }

            return clientData;
        },

        createFromVersion(client) {
            if (
                client.resourceVersion === 1 &&
                typeof client.image.data !== 'undefined'
            ) {
                client = {
                    ...client,
                    image: client.image.data,
                };
            }

            self.createOrUpdate(client);
        },

        /**
         * @returns {*}
         * @param clientData
         */
        processNestedResources(clientData) {
            if (typeof clientData.currentAgreement !== 'undefined') {
                clientData.currentAgreement = getRoot(
                    self
                ).stores.agreementStore.processAsNested(
                    clientData.currentAgreement
                );
            }

            return clientData;
        },

        processAsNested(client) {
            if (!!client?.identifier) {
                self.createOrUpdate(client);
                return client.identifier;
            }

            return client;
        },
    }));
