import React, { FunctionComponent, useContext } from 'react';
import { observer } from 'mobx-react';
import { ClassPass } from '@/Models/ClassPass';
import { ClearButton } from '@/Components/Button';
import { uiColors } from '@/Theme';
import EventConfirmationModalContext from '@/Contexts/EventConfirmationModalContext';
import Notification from '@/Services/Notification';
import RootStore from '@/Stores/RootStore';
import queryClient from '@/Modules/QueryClient';

interface Props {
    classPass: ClassPass;
    onUpdate?(classPass: ClassPass): void;
}

const Component: FunctionComponent<Props> = ({ classPass }) => {
    const { uiStateStore } = RootStore.stores;
    const confirmationContext = useContext(EventConfirmationModalContext);

    async function expireClassPass() {
        try {
            await classPass.expire();
            await queryClient.invalidateQueries(['classPasses', 'purchases']);
        } catch (e) {
            console.log(e);
            Notification.error();
            return;
        }

        Notification.success('Class pass expired');

        uiStateStore.closeStack();
    }

    function handleClick() {
        confirmationContext.requireConfirmation({
            content: () => (
                <p className="mb-2">
                    Are you sure you want to expire this class pass?
                </p>
            ),
            onConfirmed: expireClassPass,
        });
    }

    if (classPass.hasExpired) {
        return null;
    }

    return (
        <ClearButton
            onClick={handleClick}
            icon="CloseCircle"
            fill={uiColors.negative.default}
        >
            Expire now
        </ClearButton>
    );
};

export const ExpireClassPassNowButton = observer(Component);
