import mergeWith from 'lodash-es/mergeWith';
import isArray from 'lodash-es/isArray';

export const mergeFilters = (defaults: any, overrides: any) => {
    return mergeWith(
        defaults,
        overrides,
        // @ts-ignore
        function (objValue: any, srcValue: any) {
            if (isArray(objValue)) {
                return objValue.concat(srcValue);
            }
        }
    );
};
