import React, { FunctionComponent } from 'react';
import { ClassPass } from '@/Models/ClassPass';
import RootStore from '@/Stores/RootStore';
import { ClearButton } from '../../Button';

interface Props {
    classPass: ClassPass;
    onUpdate?(updatedClassPass: ClassPass): void;
}

const ClassPassUpdateButton: FunctionComponent<Props> = ({
    classPass,
    onUpdate,
}) => {
    function handleClick() {
        RootStore.stores.uiStateStore.pushModal('CLASS_PASS_UPDATE', {
            classPass,
            onUpdate,
        });
    }

    return (
        <ClearButton icon="Pencil" onClick={handleClick}>
            Edit
        </ClearButton>
    );
};

export default ClassPassUpdateButton;
