import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { FormikProps, withFormik } from 'formik';
import Modal from '@/Components/Modal';
import { PrimaryButton } from '@/Components/Button';
import { observer } from 'mobx-react';
import { StackableModalProps } from '@/Components/RootModal';
import { possessive } from '@/Services/String';
import { Client } from '@/Models/Client';
import FormField from '@/Components/FormField';
import SelectableOptionList from '@/Components/SelectableOptionList';
import FormRadioField from '@/Components/FormRadioField';
import Alert from '@/Components/Alert';
import { ClientParqResults } from '@/Features/Clients';
import RootStore from '@/Stores/RootStore';
import { PortalInvite } from '@/Models/PortalInvite';
import FormAgreementSelectField from '@/Components/FormAgreementSelectField';
import Notification from '@/Services/Notification';
import ClientTransport from '@/Services/Transport/ClientTransport';
import queryClient from '@/Modules/QueryClient';
import { queryKeys } from '@/Services/QueryKeys';
import handleServerValidation from '@/Utilities/handleServerValidation';
import DOMPurify from 'dompurify';
import FormCheckboxField from '@/Components/FormCheckboxField';
import FormTextAreaField from '@/Components/FormTextAreaField';

interface Props extends StackableModalProps {
    client: Client;
    signUpInvite: PortalInvite;
}

interface FormValues {
    canTrain: boolean | undefined;
    sendAgreement: boolean;
    agreement?: string;
    includePersonalMessage: boolean;
    personalMessage: string;
}

const Component: FunctionComponent<Props & FormikProps<FormValues>> = (
    props
) => {
    const { organisation } = RootStore.stores.currentUserStore;
    const {
        client,
        signUpInvite,
        values,
        handleSubmit,
        isSubmitting,
        setFieldValue,
    } = props;

    const isDisabled = useMemo(() => {
        return (
            values.canTrain === undefined ||
            (values.canTrain && values.sendAgreement && !values.agreement)
        );
    }, [values.canTrain, values.sendAgreement, values.agreement]);

    const buttonText = useMemo(() => {
        if (values.canTrain === false || !values.sendAgreement) {
            return 'Finish';
        }
        if (values.sendAgreement) {
            return 'Send';
        }

        return 'Continue';
    }, [values.canTrain, values.sendAgreement]);

    useEffect(() => {
        if (values.canTrain === true) {
            setFieldValue(
                'personalMessage',
                organisation.defaultClientAcceptMessage ?? ''
            );
        } else if (values.canTrain === false) {
            setFieldValue(
                'personalMessage',
                organisation.defaultClientRejectMessage ?? ''
            );
        }
    }, [values.canTrain]);

    const hasParq = (signUpInvite?.scope ?? []).includes('parq');
    const hasCompletedParq = (client.currentParq ?? []).length > 0;

    return (
        <Modal
            title={`${possessive(client.firstName)} details`}
            onSubmit={handleSubmit}
            rightAction={() => (
                <PrimaryButton isLoading={isSubmitting} disabled={isDisabled}>
                    {buttonText}
                </PrimaryButton>
            )}
            stackProps={props.stackProps}
        >
            <fieldset className="dialog__panel">
                <h2 className="ui-action-bar__title mb-2">Personal details</h2>

                <FormField>
                    <p className="m-0">
                        {client.fullName} ({client.age})
                    </p>
                    <p className="m-0">{client.email}</p>
                    <p className="m-0">{client.mobilePhone}</p>
                </FormField>
                <FormField className="!m-0">
                    <FormField.Label>In an emergency</FormField.Label>
                    <p className="m-0">
                        {client.emergencyFullName} (
                        {client.emergencyRelationship})
                    </p>
                    <p className="m-0">
                        {client.emergencyDaytimePhone} /
                        {client.emergencyEveningPhone}
                    </p>
                </FormField>
            </fieldset>

            {!!client.trainingNotes && (
                <fieldset className="dialog__panel">
                    <h2 className="ui-action-bar__title mb-2">Intentions</h2>
                    <div
                        className="prose prose-sm max-w-none"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                client.trainingNotes,
                                {}
                            ),
                        }}
                    />
                </fieldset>
            )}

            {(hasParq || hasCompletedParq) && (
                <fieldset className="dialog__panel">
                    <h2 className="ui-action-bar__title mb-2">PAR-Q</h2>

                    <FormField className="!m-0">
                        {hasCompletedParq && (
                            <ClientParqResults client={client} withAlerts />
                        )}
                        {hasParq && !hasCompletedParq && (
                            <Alert
                                type="warning"
                                title={`${client.firstName} has not yet completed their PAR-Q`}
                                className="mb-1"
                            />
                        )}
                    </FormField>
                </fieldset>
            )}

            <fieldset className="dialog__panel">
                <h2 className="ui-action-bar__title mb-2">Your decision</h2>

                <FormField className="!m-0">
                    <SelectableOptionList horizontal>
                        <FormRadioField
                            name="canTrain"
                            value={true}
                            label={
                                <span>
                                    <strong>Accept {client.firstName}</strong>
                                    <br />
                                    <span className="font-normal">
                                        They will be added to your roster
                                    </span>
                                </span>
                            }
                        />
                        <FormRadioField
                            name="canTrain"
                            value={false}
                            label={
                                <span>
                                    <strong>Reject {client.firstName}</strong>
                                    <br />
                                    <span className="font-normal">
                                        They will be added to your archive
                                    </span>
                                </span>
                            }
                        />
                    </SelectableOptionList>
                </FormField>
            </fieldset>

            {typeof values.canTrain !== 'undefined' && (
                <fieldset className="dialog__panel">
                    <h2 className="ui-action-bar__title mb-2">Options</h2>

                    {values.canTrain && (
                        <>
                            {client.hasAgreement && (
                                <Alert
                                    type="success"
                                    title={`${client.firstName} has already signed '${client.currentAgreement.name}' agreement`}
                                    className="mb-4"
                                />
                            )}
                            {!client.hasAgreement && (
                                <>
                                    <FormField>
                                        <FormCheckboxField
                                            name="sendAgreement"
                                            label="Send agreement to sign"
                                        />

                                        <FormField.Children
                                            isHidden={!values.sendAgreement}
                                        >
                                            <FormField.Row>
                                                <FormAgreementSelectField
                                                    name="agreement"
                                                    isRequired
                                                    withCreateButton
                                                    displayErrors="after-submit"
                                                    fieldProps={{
                                                        isDisabled:
                                                            !values.sendAgreement,
                                                    }}
                                                />
                                                <FormField />
                                            </FormField.Row>
                                        </FormField.Children>
                                    </FormField>
                                </>
                            )}
                        </>
                    )}

                    <FormField>
                        <FormCheckboxField
                            name="includePersonalMessage"
                            label="Include personal message"
                        />

                        <FormField.Children
                            isHidden={!values.includePersonalMessage}
                        >
                            <FormTextAreaField
                                name="personalMessage"
                                isDisabled={!values.includePersonalMessage}
                            />
                        </FormField.Children>
                    </FormField>
                </fieldset>
            )}
        </Modal>
    );
};
export const ReviewClientSignUpModal = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        canTrain: undefined,
        sendAgreement: false,
        agreement: undefined,
        includePersonalMessage: false,
        personalMessage: '',
    }),
    handleSubmit: async (values, { props, setErrors }) => {
        const { uiStateStore } = RootStore.stores;
        const { client } = props;

        try {
            await ClientTransport.completeNewClientReview(client.eid, values);
        } catch (e) {
            handleServerValidation(e, setErrors);
            return;
        }

        if (!values.canTrain) {
            await client.archive();
            await ClientTransport.completeDataReview(
                props.client.eid,
                'signed-up'
            );
        } else {
            // await
        }

        // else if (values.signNow === true) {
        //     /**
        //      * If the client is signing now, but the agreement is being sent via a link, handle that now
        //      */
        //     try {
        //         await ClientTransport.sendDocumentInviteLink(
        //             client.eid,
        //             'agreement',
        //             { agreement: values.agreement }
        //         );
        //     } catch (e) {
        //         handleServerValidation(e, setErrors);
        //         return;
        //     }
        //
        //     await ClientTransport.completeDataReview(
        //         props.client.eid,
        //         'signed-up'
        //     );
        //
        //     Notification.success('Agreement request sent');
        // } else {
        //     await ClientTransport.completeDataReview(
        //         props.client.eid,
        //         'signed-up'
        //     );
        //
        //     Notification.success('Client reviewed.');
        // }

        Notification.success('Client reviewed.');

        await queryClient.invalidateQueries(queryKeys.clients.all);

        uiStateStore.popModal();
    },
})(observer(Component));
