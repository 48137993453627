import React, { FunctionComponent } from 'react';
import { DayPickerProps } from 'react-day-picker';
import Loader from '@/Components/Loader';
import { OrganisationUser } from '@/Models/OrganisationUser';
import { UserAvatar } from '@/Features/User/Components/UserAvatar';
import { useQuery } from 'react-query';
import { queryKeys } from '@/Services/QueryKeys';
import RootStore from '@/Stores/RootStore';
import { Button } from '@/Components/Button';
import { ScheduleEntryPopperActionType } from '@/Features/Schedule';
import classNames from 'classnames';

interface Props extends DayPickerProps {
    card: any;
    view: any;
    action: any;
    onDisplayChange(display: ScheduleEntryPopperActionType): void;
}

export const ScheduleEntryPopperContentUserSelect: FunctionComponent<Props> = ({
    card,
    view,
    action,
    onDisplayChange,
}) => {
    const usersQuery = useQuery(queryKeys.teamMembers.list(), () =>
        RootStore.stores.userStore.list()
    );

    function handleUserClick(user: OrganisationUser) {
        onDisplayChange('options');

        card.setAssignedUser(user.uuid);
        view.setCurrentCalendar(user.uuid);
        action.updateCard(card);
        view.applyScope();
    }

    return (
        <>
            <div className="flex h-14 items-center gap-2 p-2">
                <Button
                    icon="Close"
                    onClick={() => onDisplayChange('options')}
                />
                <div className="flex-1 text-center font-bold">
                    Change teacher
                </div>
                <div className="w-10" />
            </div>
            <div className="relative flex h-[200px] flex-col overflow-auto p-2">
                {usersQuery.isLoading && <Loader />}
                {usersQuery.data?.map((user: OrganisationUser) => (
                    <div
                        key={user.uuid}
                        className={classNames(
                            'group flex w-full cursor-pointer items-center gap-2 rounded p-1 transition-colors',
                            {
                                'bg-selected-50':
                                    card.entry.assignedUser === user.uuid,
                                'hover:bg-gray-50':
                                    card.entry.assignedUser !== user.uuid,
                            }
                        )}
                        onClick={() => handleUserClick(user)}
                    >
                        <UserAvatar
                            user={user}
                            className="h-7 w-7 group-hover:bg-white"
                        />
                        <span>
                            {user.firstName} {user.lastName}
                        </span>
                    </div>
                ))}
            </div>
        </>
    );
};
