import React, { FunctionComponent } from 'react';
import FormSelectField from '../FormSelectField';
import { FormFieldElementProps } from '../FormFieldElements';
import { SelectFieldProps } from '../SelectField';
import { paymentMethodOptions } from '@/Config/options';

interface Props extends FormFieldElementProps {
    fieldProps?: SelectFieldProps;
}

const FormPaymentMethodSelectField: FunctionComponent<Props> = (props) => {
    return (
        <FormSelectField
            options={paymentMethodOptions.map((method: string) => ({
                value: method,
                label: method,
            }))}
            label="Method"
            {...props}
        />
    );
};

export default FormPaymentMethodSelectField;
