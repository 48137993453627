import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Agreement } from '@/Models/Agreement';
import {
    DeleteAgreementButton,
    DuplicateAgreementButton,
    SetAgreementAsDefaultButton,
    UpdateAgreementButton,
} from '@/Features/Agreements';
import { NewPopper } from '@/Components/NewPopper';

interface Props {
    agreement: Agreement;
}

const Component: FunctionComponent<Props> = ({ agreement }) => {
    return (
        <NewPopper placement="bottom-end">
            {() => (
                <div className="ui-button-list">
                    <UpdateAgreementButton agreement={agreement} />
                    <DuplicateAgreementButton agreement={agreement} />
                    <DeleteAgreementButton agreement={agreement} />
                </div>
            )}
        </NewPopper>
    );
};

export const AgreementOptionsMenu = observer(Component);
