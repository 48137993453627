import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import classNames from 'classnames';
import {
    ClientAvatarSize,
    ClientModel,
    ClientOccasionIcon,
} from '@/Features/Clients';
import Avatar from '@/Components/Avatar';
import MissingUserAvatar from '@/Components/MissingUserAvatar';
import { NavLink } from 'react-router-dom';
import { clientUrl } from '../Utils';
import { initialed } from '@/Services/String';

interface Props {
    client?: Client | ClientModel;
    size?: ClientAvatarSize;
    withLink?: boolean;
    withName?: boolean;
    withOccasionIcon?: boolean;
    className?: string;
    imageClassName?: string;
    accessory?: ReactNode;
}
const components = {
    div: 'div',
    NavLink: NavLink,
};

const Component: FunctionComponent<Props> = ({
    client,
    withLink = false,
    withName = false,
    withOccasionIcon = true,
    className,
    imageClassName = 'w-9 h-9',
    accessory,
}) => {
    const [Component, props] = useMemo(() => {
        return [
            withLink ? components.NavLink : 'div',
            {
                className: classNames('flex flex-col items-center', {
                    'rounded hover:opacity-50 cursor-pointer transition':
                        withLink,
                }),
                to: withLink ? clientUrl(client) : undefined,
            },
        ];
    }, [withLink, client]);

    return (
        <div className={classNames('flex flex-col items-center', className)}>
            <Component {...props}>
                <div className={classNames('relative', imageClassName)}>
                    {withOccasionIcon && (
                        <ClientOccasionIcon
                            client={client}
                            className="absolute -left-2 -top-2"
                        />
                    )}
                    <Avatar
                        src={client?.image?.urls?.original}
                        alt={client?.fullName}
                        className={classNames('h-full w-full')}
                        missingIcon={<MissingUserAvatar />}
                    />
                </div>

                {withName && (
                    <p
                        className={classNames('mt-1 text-center font-bold', {
                            'text-blue-600': withLink,
                        })}
                    >
                        {initialed(client.firstName, client.lastName)}
                    </p>
                )}
            </Component>

            {!!accessory && <div className="mt-1">{accessory}</div>}
        </div>
    );
};

export const ClientAvatar = observer(Component);
