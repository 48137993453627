import React, { FunctionComponent } from 'react';
import {
    ButtonProps,
    PrimaryButton,
    PrimaryResponsiveButton,
    WhiteButton,
} from '../../Button';
import { observer } from 'mobx-react';
import RootStore from '../../../Stores/RootStore';
import SummaryButton from '../../SummaryButton';
import { Package } from '@/Models/Package';

interface Props {
    className?: string;
    variant?: 'inline' | 'default' | 'detail';
    isResponsive?: boolean;
    packageType?: 'class' | 'session';
    removeBeneath?: boolean;
    title?: string;
    subtitle?: string;
    onCreate?(packageModel: Package): void;
}

const PackageCreateButton: FunctionComponent<Props> = ({
    className,
    isResponsive = false,
    variant = 'default',
    packageType,
    onCreate,
    removeBeneath = false,
    ...props
}) => {
    const { uiStateStore } = RootStore.stores;

    const handleClick = () => {
        uiStateStore.pushModal(
            'PACKAGE_CREATE',
            {
                packageType,
                onCreate,
            },
            removeBeneath
        );
    };

    const buttonProps: ButtonProps = {
        type: 'button',
        icon: 'Plus',
        className,
        onClick: handleClick,
    };
    const title = props.title ?? 'Create a package';

    if (variant === 'inline') {
        return <WhiteButton {...buttonProps}>New</WhiteButton>;
    }

    if (variant === 'detail') {
        return (
            <SummaryButton
                title={title}
                subtitle={props.subtitle}
                onClick={handleClick}
                className={className}
            />
        );
    }

    return isResponsive ? (
        <PrimaryResponsiveButton {...buttonProps}>
            Package
        </PrimaryResponsiveButton>
    ) : (
        <PrimaryButton {...buttonProps}>Package</PrimaryButton>
    );
};

export default observer(PackageCreateButton);
