import * as Sentry from '@sentry/browser';
import * as FullStory from '@fullstory/browser';
import RootStore from '../Stores/RootStore';

class CustomerSupport {
    EVENT_NAME_SIGN_UP_STEP_COMPLETED = 'Sign Up Step Completed';

    SIGN_UP_STEP_BASICS = 'basics';
    SIGN_UP_STEP_LOCALE = 'locale';
    SIGN_UP_STEP_BUSINESS = 'business';
    SIGN_UP_STEP_DELIVERY = 'delivery';
    SIGN_UP_STEP_PORTAL = 'client-area';
    SIGN_UP_STEP_COMPLETE = 'complete';

    SERVICES_DEFAULT = ['gist', 'google'];
    SERVICES_ALL = ['gist', 'google', 'facebook'];

    currentPathName = null;

    async newUserRegistered(user) {
        this.completeSignUpStep(this.SIGN_UP_STEP_BASICS, user);
        this.trackEvent('Complete Registration', {}, this.SERVICES_ALL);
    }

    completeSignUpStep(stepCompleted, user, additionalData = {}) {
        if (typeof window.gist !== 'undefined') {
            window.gist.identify(user.eid, {
                email: user.email,
                name: user.fullName,
                country: user.locale?.countryCode,
                timezone: user.locale?.timezone,
                trial_ends: user.trialEndsAt,
                sign_up_progress: stepCompleted,
                ...additionalData,
            });
        }

        this.trackEvent(this.EVENT_NAME_SIGN_UP_STEP_COMPLETED, {
            step: stepCompleted,
        });
    }

    pushUserData(userData = {}) {
        const { user, organisation } = RootStore.stores.currentUserStore;

        if (typeof window.gist !== 'undefined') {
            window.gist.identify(user.eid, {
                email: user.email,
                name: user.fullName,
                country: user.locale.countryCode,
                timezone: user.locale.timezone,
                striive_payments: organisation.canReceivePayment,
                striive_portal: organisation.portalEnabled,
                striive_portal_login: organisation.portalLoginEnabled,
                // trial_ends: user.billing.onTrial
                //     ? user.billing.trialEndsAt.toSeconds()
                //     : null,
                ...this.determineOrganisationRoles(user.organisations),
                ...(user.signUpState === 'complete'
                    ? { sign_up_progress: this.SIGN_UP_STEP_COMPLETE }
                    : {}),
                ...userData,
            });
        }

        if (import.meta.env.VITE_FS_ID) {
            FullStory.identify(user.eid, {
                displayName: `${user.firstName} ${user.lastName
                    .toUpperCase()
                    .charAt(0)}.`,
                email: user.email,
            });
        }

        if (import.meta.env.VITE_SENTRY_DSN) {
            Sentry.setUser({
                id: user.eid,
            });
        }
    }

    trackEvent(name, meta = {}, services = this.SERVICES_DEFAULT) {
        // console.groupCollapsed(`%cEvent '${name}'`, 'color:green');
        // console.log('Sent to: ' + services.join(', '));
        // console.log('Data: ', meta);
        // console.groupEnd();

        const simpleName = name.replace(' ', '');

        if (
            typeof window.dataLayer?.push === 'function' &&
            services.includes('google')
        ) {
            window.dataLayer.push({ event: simpleName, ...meta });
        }

        if (typeof window.fbq === 'function' && services.includes('facebook')) {
            window.fbq('track', simpleName, meta);
        }

        if (typeof window.gist !== 'undefined' && services.includes('gist')) {
            window.gist.track(name, meta);
        }
    }

    shutdown() {
        if (
            typeof window.gist !== 'undefined' &&
            typeof window.gist.chat === 'function'
        ) {
            window.gist.chat('shutdown');
        }
    }

    pageChange(newPathname) {
        if (typeof window.gtag === 'function') {
            window.gtag('config', window.gaCode, {
                page_path: `${window.location.href}${window.location.search}`,
            });
        }
        if (
            typeof window.gist !== 'undefined' &&
            newPathname !== this.currentPathName
        ) {
            this.currentPathName = newPathname;
            window.gist.trackPageView();
        }
    }

    determineOrganisationRoles(organisations) {
        const owner = organisations
            .filter((o) => o.isOwner)
            .map((o) => o.displayName)
            .join(', ');
        const teacher = organisations
            .filter((o) => !o.isOwner)
            .map((o) => o.displayName)
            .join(', ');

        return {
            owner,
            teacher,
        };
    }
}

export default new CustomerSupport();
