import queryClient from '@/Modules/QueryClient';
import { queryKeys } from '@/Services/QueryKeys';
import { classDateQueryKeys } from '@/Features/ClassDates';
import { keys as waitlistRegistrationQueryKeys } from '@/Features/WaitlistRegistrations/Queries/keys';

export const logQueryKeys = () => {
    const queryCache = queryClient.getQueryCache();
    const queryKeys = queryCache.getAll().map((cache) => cache.queryKey);

    console.log(queryKeys);
};

export const invalidateClassDateEnrolmentQueries = async (
    classDateUuid: string,
    clientEid: string,
    classUuid?: string
) => {
    await queryClient.invalidateQueries(
        queryKeys.enrolments.list({
            classDate: classDateUuid,
        })
    );

    await queryClient.invalidateQueries(classDateQueryKeys.all);
    await queryClient.invalidateQueries(`clients.${clientEid}.nextEvents`);

    if (!!classUuid) {
        await queryClient.invalidateQueries(queryKeys.classes.view(classUuid));
    }

    await queryClient.invalidateQueries(
        waitlistRegistrationQueryKeys.listsInfinite()
    );
};
