import React, { FunctionComponent } from 'react';
import { Client } from '../../Models/Client';
import FormField from '../FormField';
import Notice from '../Notice';
import RootStore from '../../Stores/RootStore';

interface Props {
    client: Client;
}

const StriivePaymentsLinkWarning: FunctionComponent<Props> = ({ client }) => {
    const { canReceivePayment } =
        RootStore.stores.currentUserStore.organisation;

    if (canReceivePayment && !!client.email) {
        return null;
    }

    return (
        <FormField.Info>
            {!canReceivePayment && (
                <Notice type="warning" className="mt-2">
                    Striive Payments must be enabled to send a payment link.
                </Notice>
            )}
            {canReceivePayment && !client.email && (
                <Notice type="warning" className="mt-2">
                    {client.firstName} does not have an email address.
                </Notice>
            )}
        </FormField.Info>
    );
};

export default StriivePaymentsLinkWarning;
