import { AlertType } from '@/Components/Alert';

type ProcessColorsTypes = {
    [key in 'wrapper' | 'svg']: string;
};

export const alertColors = (colorType: AlertType): ProcessColorsTypes =>
    ({
        success: {
            wrapper: 'bg-legacy-positive-300 text-legacy-positive-900',
            svg: 'text-legacy-positive-900',
        },
        error: {
            wrapper: 'bg-red-50 text-red-800',
            svg: 'fill-current text-red-400',
        },
        warning: {
            wrapper: 'bg-yellow-50 text-yellow-800',
            svg: 'fill-current text-yellow-400',
        },
        info: {
            wrapper: 'bg-blue-50 text-gray-700',
            svg: 'fill-current text-blue-400',
        },
        grey: {
            wrapper: 'bg-gray-100 text-gray-800',
            svg: 'fill-current text-gray-400',
        },
    }[colorType]);
