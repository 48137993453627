import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import classNames from 'classnames';
import { Tooltip } from '@/Components/Tooltip';
import {
    isClientBirthday,
    isClientOccasion,
    clientAnniversaryString,
    clientBirthdayString,
} from '../Utils/Occasions';
import { ClientModel } from '../Types';

interface Props {
    client?: Client | ClientModel;
    className?: string;
}

const Component: FunctionComponent<Props> = ({ client, className }) => {
    if (!isClientOccasion(client)) {
        return null;
    }

    const isBirthday = isClientBirthday(client);

    return (
        <Tooltip
            label={
                isBirthday
                    ? clientBirthdayString(client, false)
                    : clientAnniversaryString(client, false)
            }
        >
            <span
                className={classNames(
                    'z-10 inline-flex h-6 w-6 min-w-[24px] items-center justify-center rounded-full bg-white',
                    className
                )}
            >
                {isBirthday ? '🎂' : '🎉'}
            </span>
        </Tooltip>
    );
};

export const ClientOccasionIcon = observer(Component);
