import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Loader from '../Loader';
import { ButtonProps } from '../Button';

interface Props extends ButtonProps {
    title?: string | JSX.Element;
    subtitle?: string | JSX.Element;
}

const SummaryButton: FunctionComponent<Props> = ({
    title,
    subtitle,
    className,
    isLoading,
    ...props
}) => (
    <a
        className={classNames('button --selectable-option relative', className)}
        {...props}
    >
        {props.children}
        {isLoading && <Loader withPosition withShroud />}
        {title && <span className="button__title">{title}</span>}
        {subtitle && <span className="button__sub-title">{subtitle}</span>}
    </a>
);

export default SummaryButton;
