import React, { FunctionComponent } from 'react';
import FormSelectField, { FormSelectFieldProps } from '../FormSelectField';
import { observer } from 'mobx-react';
import { Package } from '@/Models/Package';
import { useFormikContext } from 'formik';
import PackageCreateButton from '@/Components/Package/CreateButton';

interface Props extends FormSelectFieldProps {
    type?: 'all' | 'class' | 'session';
    packages: Package[];
    withCustom?: boolean;
}

const FormClassPassPackageSelectField: FunctionComponent<Props> = ({
    packages,
    type = 'all',
    withCustom = false,
    ...props
}) => {
    const { setFieldValue } = useFormikContext();

    let options = packages.map((packageModel: Package) => ({
        value: packageModel.uuid,
        label: packageModel.name,
    }));

    const handleCreate = (classPassPackage: Package) => {
        setFieldValue(props.name, classPassPackage.uuid);
    };

    return (
        <FormSelectField
            options={options}
            fieldAccessoryAfter={
                <PackageCreateButton
                    className="ml-2"
                    onCreate={handleCreate}
                    variant="inline"
                    packageType="class"
                />
            }
            {...props}
        />
    );
};

export default observer(FormClassPassPackageSelectField);
