import React, { ComponentPropsWithoutRef, FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import useAuth from '@/Hooks/useAuth';
import { NewPopper } from '@/Components/NewPopper';
import { Button } from '@/Components/Button';
import {
    ClassDateEnrolButton,
    CreateWaitlistRegistrationButton,
} from '@/Features/ClassDates';
import { ClassDate } from '@/Models/ClassDate';
import RootStore from '@/Stores/RootStore';

interface Props extends ComponentPropsWithoutRef<'p'> {
    classDate: ClassDate;
}

const Component: FunctionComponent<Props> = ({ classDate, ...props }) => {
    const { hasRole } = useAuth();
    const { organisation } = RootStore.stores.currentUserStore;

    if (!classDate.isPrivate || !hasRole('Owner')) {
        return null;
    }

    if (classDate.isFull && organisation.waitlistEnabled) {
        return (
            <NewPopper
                placement="bottom-end"
                anchor={<Button icon="AccountPlus" />}
            >
                {() => (
                    <div className="ui-button-list">
                        <ClassDateEnrolButton
                            variant="menu"
                            classDate={classDate}
                        />
                        <CreateWaitlistRegistrationButton
                            variant="menu"
                            classDate={classDate}
                        />
                    </div>
                )}
            </NewPopper>
        );
    }

    return <ClassDateEnrolButton classDate={classDate} />;
};

export const ClassDateEnrolOptionsButton = observer(Component);
