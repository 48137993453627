import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { FormikProps, withFormik } from 'formik';
import Modal from '@/Components/Modal';
import { StackableModalProps } from '@/Components/RootModal';
import { ClassDate } from '@/Models/ClassDate';
import FormField from '@/Components/FormField';
import FormClientSelectField from '@/Components/FormClientSelectField';
import RootStore from '@/Stores/RootStore';
import FormCheckboxField from '@/Components/FormCheckboxField';
import Notice from '@/Components/Notice';
import { PrimaryButton } from '@/Components/Button';
import Notification from '@/Services/Notification';
import SelectableOptionList from '@/Components/SelectableOptionList';
import FormRadioField from '@/Components/FormRadioField';
import FormPaymentMethodSelectField from '@/Components/FormPaymentMethodSelectField';
import FormDateField from '@/Components/FormDateField';
import { inUserZone } from '@/Services/Date';
import StriivePaymentsLinkWarning from '@/Components/StriivePaymentsLinkWarning';
import StriivePaymentsMicroIcon from '@/Components/StriivePayments/MicroIcon';
import { currencyDisplay } from '@/Services/Currency';
import { OptionTypeBase } from 'react-select/src/types';
import ClassDateTransport from '@/Services/Transport/ClassDateTransport';
import Loader from '@/Components/Loader';
import handleServerValidation from '@/Utilities/handleServerValidation';
import EnrolmentMethodOptions from '@/Features/Enrolments/Components/EnrolmentMethodOptions';
import { invalidateClassDateEnrolmentQueries } from '@/Utilities/query';
import { ClientModel } from '@/Features/Clients/Types';

export interface EnrolmentFormValues {
    client: string;
    classPass: string;
    enrolmentMethod: '' | 'classPass' | 'membership' | 'single';
    paymentAction: '' | 'immediate' | 'link';
    paymentMethod: string;
    paymentDate: Date;
    confirmation: boolean;
}

interface Props extends StackableModalProps {
    classDate: ClassDate;
    client?: ClientModel;
}

const Component: FunctionComponent<
    Props & FormikProps<EnrolmentFormValues>
> = ({ classDate, ...props }) => {
    const { clientStore, currentUserStore } = RootStore.stores;
    const { canReceivePayment } = currentUserStore.organisation;
    const { values, handleSubmit, isSubmitting, setFieldValue } = props;
    const [isBusy, setIsBusy] = useState(true);
    const [enrolled, setEnrolled] = useState<string[]>([]);

    const selectedClient = useMemo(() => {
        return clientStore.find(values.client);
    }, [values.client, clientStore.clients, enrolled]);

    async function fetchData() {
        setIsBusy(true);

        try {
            await clientStore.list();
            const { data } = await ClassDateTransport.listEnrolled(
                classDate.uuid
            );
            setEnrolled(data);
        } catch (e) {
            console.log('error', e);
        }

        setIsBusy(false);
    }

    useEffect(() => {
        fetchData();
    }, [classDate]);

    useEffect(() => {
        if (values.client && selectedClient) {
            setFieldValue('enrolmentMethod', '');
        }
    }, [values.client, clientStore.clients]);

    useEffect(() => {
        if (values.enrolmentMethod !== 'classPass') {
            setFieldValue('classPass', '');
        }
        if (values.enrolmentMethod !== 'membership') {
            setFieldValue('membership', '');
        }
        if (values.enrolmentMethod !== 'single') {
            setFieldValue('paymentAction', 'immediate');
            setFieldValue('paymentMethod', '');
        }
    }, [values.enrolmentMethod]);

    const canSendConfirmation = useMemo(() => {
        return (
            values.enrolmentMethod === 'classPass' ||
            values.paymentAction === 'immediate'
        );
    }, [values.enrolmentMethod, values.paymentAction]);

    function isAlreadyEnrolled(opt: OptionTypeBase) {
        return enrolled.includes(opt.value);
    }

    return (
        <Modal
            title="Class enrolment"
            onSubmit={handleSubmit}
            rightAction={() => (
                <PrimaryButton type="submit" isLoading={isSubmitting}>
                    {values.enrolmentMethod === 'single' &&
                    values.paymentAction === 'link'
                        ? 'Send'
                        : 'Enrol'}
                </PrimaryButton>
            )}
            {...props}
        >
            {isBusy && <Loader withShroud withPosition />}

            <section className="dialog__panel">
                <h2 className="ui-action-bar__title mb-2">Client</h2>

                <FormField.Row className="!m-0">
                    <FormClientSelectField
                        name="client"
                        className="m-0"
                        fieldProps={{
                            isOptionDisabled: isAlreadyEnrolled,
                        }}
                    />
                    <FormField />
                </FormField.Row>
            </section>

            {selectedClient && (
                <EnrolmentMethodOptions
                    classDate={classDate}
                    client={selectedClient}
                />
            )}

            {selectedClient && values.enrolmentMethod === 'single' && (
                <section className="dialog__panel">
                    <h2 className="ui-action-bar__title mb-2">
                        Payment of{' '}
                        {currencyDisplay(
                            classDate.enrolmentCost,
                            classDate.currency
                        )}
                    </h2>

                    <FormField>
                        <SelectableOptionList horizontal>
                            <FormRadioField
                                name="paymentAction"
                                value="immediate"
                                label={
                                    <span>
                                        <strong>Record payment now</strong>
                                        <br />
                                        <span className="font-normal">
                                            Enrol{' '}
                                            {selectedClient?.firstName ??
                                                'your client'}{' '}
                                            onto this class now and add a manual
                                            record of their payment.
                                        </span>
                                    </span>
                                }
                            />
                            <FormRadioField
                                name="paymentAction"
                                value="link"
                                label={
                                    <span>
                                        <strong className="relative">
                                            Send payment invite{' '}
                                            <StriivePaymentsMicroIcon />
                                        </strong>
                                        <br />
                                        <span className="font-normal">
                                            Email{' '}
                                            {selectedClient?.firstName ??
                                                'your client'}{' '}
                                            a link to purchase this enrolment
                                            online using Striive Payments.
                                        </span>
                                    </span>
                                }
                                isDisabled={
                                    !selectedClient.email || !canReceivePayment
                                }
                            />
                        </SelectableOptionList>

                        <StriivePaymentsLinkWarning client={selectedClient} />
                    </FormField>

                    {values.paymentAction === 'immediate' && (
                        <FormField.Row>
                            <FormPaymentMethodSelectField
                                name="paymentMethod"
                                isRequired
                            />
                            <FormDateField
                                name="paymentDate"
                                label="Date"
                                maxDate={new Date()}
                                isRequired
                            />
                        </FormField.Row>
                    )}
                </section>
            )}

            {selectedClient && values.enrolmentMethod && canSendConfirmation && (
                <section className="dialog__panel">
                    <h2 className="ui-action-bar__title mb-2">Notifications</h2>

                    <FormField>
                        <FormCheckboxField
                            name="confirmation"
                            label={`Send ${selectedClient.firstName} an enrolment confirmation`}
                            isDisabled={!selectedClient.hasEmail}
                        />
                        {!selectedClient.hasEmail && (
                            <div className="mt-2">
                                <Notice type="warning">
                                    {selectedClient.firstName} does not have an
                                    email address.
                                </Notice>
                            </div>
                        )}
                    </FormField>
                </section>
            )}
        </Modal>
    );
};

export const ClassDateEnrolModal = withFormik<Props, EnrolmentFormValues>({
    mapPropsToValues: (props) => ({
        client: props.client?.eid ?? '',
        classPass: '',
        enrolmentMethod: '',
        paymentAction: 'immediate',
        paymentMethod: '',
        paymentDate: inUserZone()!.toJSDate(),
        confirmation: true,
    }),
    handleSubmit: async (values, { props, setErrors }) => {
        const { uiStateStore } = RootStore.stores;

        try {
            await props.classDate.enrol(values);

            await invalidateClassDateEnrolmentQueries(
                props.classDate.uuid,
                values.client,
                props.classDate.references.class
            );
        } catch (e) {
            console.log(e);
            handleServerValidation(e, setErrors);

            return;
        }

        const message =
            values.enrolmentMethod === 'single' &&
            values.paymentAction === 'link'
                ? 'Purchase link sent.'
                : 'Class enrolment saved.';
        Notification.success(message);

        uiStateStore.popModal();
    },
})(observer(Component));
