import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import ClassPassUpdateButton from '@/Components/ClassPass/UpdateButton';
import { ClassPass } from '@/Models/ClassPass';
import { NewPopper } from '@/Components/NewPopper';
import {
    DeleteClassPassButton,
    ExpireClassPassNowButton,
} from '@/Features/ClassPasses';

interface Props {
    classPass: ClassPass;
}

const Component: FunctionComponent<Props> = ({ classPass }) => {
    return (
        <NewPopper placement="bottom-end">
            {() => (
                <div className="ui-button-list">
                    <ClassPassUpdateButton classPass={classPass} />
                    <ExpireClassPassNowButton classPass={classPass} />
                    <DeleteClassPassButton classPass={classPass} />
                </div>
            )}
        </NewPopper>
    );
};

export const ClassPassOptionsMenu = observer(Component);
