import React, { ComponentPropsWithoutRef, FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import { ParqAnswer } from '@/Models/ParqAnswer';
import NotesField from '@/Components/Notes/Field';
import Alert from '@/Components/Alert';
import { countInflect } from '@/Services/String';

interface Props extends ComponentPropsWithoutRef<'div'> {
    client: Client;
    withAlerts?: boolean;
}

const Component: FunctionComponent<Props> = ({
    client,
    withAlerts = false,
    ...props
}) => {
    function handleNotesSaved(parq: ParqAnswer, notes: string | null) {
        client.updateParqAnswer(parq.questionId, notes);
    }

    if (!client.failedParqs && !withAlerts) {
        return null;
    }

    return (
        <div {...props}>
            {client.failedParqs.length === 0 && withAlerts && (
                <Alert
                    type="success"
                    title={`${client.firstName} answered "No" to all questions`}
                />
            )}
            {client.failedParqs.length > 0 && (
                <>
                    <div className="grid gap-4">
                        {withAlerts && (
                            <Alert
                                type="warning"
                                title={`${
                                    client.firstName
                                } answered "Yes" to ${countInflect(
                                    client.failedParqs.length,
                                    'question',
                                    'questions'
                                )}`}
                            />
                        )}

                        {client.failedParqs.map(
                            (parq: ParqAnswer, index: number) => (
                                <div
                                    key={index}
                                    className="border-b border-t border-gray-200 bg-white xl:rounded-lg xl:border"
                                >
                                    <header className="border-b border-gray-200 p-5">
                                        <p className="font-bold">
                                            {parq.question}
                                        </p>
                                    </header>
                                    <section className="border-b border-gray-200 p-5">
                                        <p>"{parq.clientNotes}"</p>
                                    </section>

                                    <section className="p-5">
                                        <NotesField
                                            content={parq.trainerNotes}
                                            onSave={async (notes) =>
                                                await handleNotesSaved(
                                                    parq,
                                                    notes
                                                )
                                            }
                                        />
                                    </section>
                                </div>
                            )
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export const ClientParqResults = observer(Component);
