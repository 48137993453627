import { getRoot, types } from 'mobx-state-tree';

import { AgreementStore } from './AgreementStore';
import { ClassStore } from './ClassStore';
import { ClassPassStore } from './ClassPassStore';
import { ClientStore } from './ClientStore';
import { MembershipSubscriptionStore } from './MembershipSubscriptionStore';
import { MessageStore } from './MessageStore';
import { CurrentUserStore } from './CurrentUserStore';
import { EntryStore } from './EntryStore';
import { LocationStore } from './LocationStore';
import { MetricStore } from './MetricStore';
import { TaskStore } from './TaskStore';
import { EmployerStore } from './EmployerStore';
import { EnrolmentStore } from './EnrolmentStore';
import { PackageStore } from './PackageStore';
import { ExpenseStore } from './ExpenseStore';
import { ExpenseCategoryStore } from './ExpenseCategoryStore';
import { SessionPackStore } from './SessionPackStore';
import { TransactionStore } from './TransactionStore';
import { PortalInviteStore } from './PortalInviteStore';
import { MembershipStore } from './MembershipStore';
import { UiStateStore } from './UiStateStore';
import { RolesStore } from './RolesStore';
import { UserStore } from './UserStore';
import { WaitlistRegistrationStore } from './WaitlistRegistrationStore';
import SocketStore from './SocketStore';

const RootStore = types
    .model('RootStore', {
        bootstrapped: false,
        stores: types.model({
            agreementStore: AgreementStore,
            classStore: ClassStore,
            classPassStore: ClassPassStore,
            clientStore: ClientStore,
            membershipSubscriptionStore: MembershipSubscriptionStore,
            currentUserStore: CurrentUserStore,
            employerStore: EmployerStore,
            enrolmentStore: EnrolmentStore,
            entryStore: EntryStore,
            expenseStore: ExpenseStore,
            expenseCategoryStore: ExpenseCategoryStore,
            locationStore: LocationStore,
            membershipStore: MembershipStore,
            messageStore: MessageStore,
            metricStore: MetricStore,
            packageStore: PackageStore,
            portalInviteStore: PortalInviteStore,
            sessionPackStore: SessionPackStore,
            taskStore: TaskStore,
            transactionStore: TransactionStore,
            uiStateStore: UiStateStore,
            rolesStore: RolesStore,
            userStore: UserStore,
            waitlistRegistrationStore: WaitlistRegistrationStore,
        }),
    })
    .actions((self) => ({
        boot() {
            const initialData = window.App.appData;

            if (initialData !== null) {
                const jsonData =
                    typeof initialData === 'string'
                        ? initialData
                        : JSON.stringify(initialData);

                const { user, organisation } = JSON.parse(jsonData);

                getRoot(self).stores.currentUserStore.init(user, organisation);

                SocketStore.connect();

                self.bootstrapped = true;
            }

            if (!typeof organisation === 'undefined') {
                return;
            }

            self.stores.userStore.list();
            self.stores.locationStore.listCombined({
                'with-trashed': true,
            });
        },

        clearAfterSwitch() {
            // self.stores.currentUserStore.clear();
            // self.stores.agreementStore.clear();
            // self.stores.classStore.clear();
            // self.stores.classPassStore.clear();
            self.stores.clientStore.clearStore();
            // self.stores.membershipSubscriptionStore.clear();
            // self.stores.employerStore.clear();
            // self.stores.enrolmentStore.clear();
            // self.stores.entryStore.clear();
            // self.stores.expenseStore.clear();
            // self.stores.expenseCategoryStore.clear();
            self.stores.locationStore.clearStore();
            // self.stores.membershipStore.clear();
            // self.stores.messageStore.clear();
            // self.stores.metricStore.clear();
            // self.stores.packageStore.clear();
            // self.stores.portalInviteStore.clear();
            // self.stores.sessionPackStore.clear();
            // self.stores.taskStore.clear();
            // self.stores.transactionStore.clear();
            // self.stores.uiStateStore.clear();
            // self.stores.rolesStore.clear();
            // self.stores.userStore.clear();
        },
    }));

export default RootStore.create({
    stores: {
        agreementStore: AgreementStore.create(),
        classStore: ClassStore.create(),
        classPassStore: ClassPassStore.create(),
        membershipSubscriptionStore: MembershipSubscriptionStore.create(),
        currentUserStore: CurrentUserStore.create(),
        messageStore: MessageStore.create(),
        metricStore: MetricStore.create(),
        entryStore: EntryStore.create(),
        employerStore: EmployerStore.create(),
        clientStore: ClientStore.create(),
        sessionPackStore: SessionPackStore.create(),
        taskStore: TaskStore.create(),
        membershipStore: MembershipStore.create(),
        locationStore: LocationStore.create(),
        expenseStore: ExpenseStore.create(),
        enrolmentStore: EnrolmentStore.create(),
        expenseCategoryStore: ExpenseCategoryStore.create(),
        transactionStore: TransactionStore.create(),
        packageStore: PackageStore.create(),
        portalInviteStore: PortalInviteStore.create(),
        uiStateStore: UiStateStore.create(),
        rolesStore: RolesStore.create(),
        userStore: UserStore.create(),
        waitlistRegistrationStore: WaitlistRegistrationStore.create(),
    },
});
