import React, {
    ComponentPropsWithoutRef,
    FunctionComponent,
    useMemo,
    useState,
} from 'react';
import { observer } from 'mobx-react';
import {
    classDateQueryKeys,
    ClassDateSelectedOptionsMenu,
    useListInfiniteClassDatesQuery,
} from '@/Features/ClassDates';
import InfiniteItemList from '@/Components/InfiniteItemList';
import { ClassDate } from '@/Models/ClassDate';
import classNames from 'classnames';
import Checkbox from '@/Components/Checkbox';
import ClassDateEntryCard from '@/Components/ClassDateEntryCard';
import ClassDateEnrolmentSummary from '@/Components/ClassDate/EnrolmentSummary';
import NotesField from '@/Components/Notes/Field';
import queryClient from '@/Modules/QueryClient';
import ZeroContent from '@/Components/ZeroContent';
import ScheduleAddEntryDatesButton from '@/Components/Schedule/AddEntryDatesButton';
import { inUserZone, toISO } from '@/Services/Date';
import { Class } from '@/Models/Class';

interface Props extends ComponentPropsWithoutRef<'p'> {
    classModel: Class;
    filters: any;
}

const Component: FunctionComponent<Props> = ({ classModel, filters }) => {
    const [selected, setSelected] = useState<string[]>([]);

    const params = useMemo(
        () => ({
            filter: {
                class: classModel.uuid,
                'starting-after': toISO(inUserZone()),
                ...filters,
            },
            sort: 'start',
            include: ['enrolments.client'],
        }),
        [classModel.uuid, filters]
    );

    const queryResult = useListInfiniteClassDatesQuery(params, {
        staleTime: 0,
    });

    const handleSelected = (uuid: string) => {
        if (selected.includes(uuid)) {
            setSelected(selected.filter((cd) => cd !== uuid));
        } else {
            setSelected([...selected, uuid]);
        }
    };

    const handleClearSelected = () => {
        setSelected([]);
    };

    return (
        <>
            {selected.length > 0 && (
                <div className="flex px-4 pb-4 pt-0 xl:px-0 xl:pb-4">
                    <ClassDateSelectedOptionsMenu
                        classContainer={classModel}
                        selected={selected}
                        onClearSelected={handleClearSelected}
                    />
                </div>
            )}

            <InfiniteItemList<ClassDate>
                queryResult={queryResult}
                renderItem={(classDate, classes) => (
                    <div
                        className={classNames(
                            classes,
                            'min-h-0 xl:auto-cols-auto xl:grid-flow-col'
                        )}
                    >
                        <div className="relative flex flex-col gap-3 lg:flex-row lg:gap-4">
                            <div className="absolute lg:static">
                                <Checkbox
                                    checked={selected.includes(classDate.uuid)}
                                    onChange={() =>
                                        handleSelected(classDate.uuid)
                                    }
                                    className="mt-4"
                                />
                            </div>
                            <div className="w-full lg:max-w-[360px]">
                                <div className="pl-[44px] lg:pl-0">
                                    <ClassDateEntryCard
                                        withLink
                                        withOptionsAccessory
                                        titleType="datetime"
                                        subtitleTypes={[
                                            'location',
                                            'assignedUsers',
                                        ]}
                                        classDate={classDate}
                                        className="w-full"
                                    />
                                </div>
                                {classDate.isPrivate && (
                                    <ClassDateEnrolmentSummary
                                        classDate={classDate}
                                    />
                                )}
                            </div>
                            <div className="flex-1">
                                <NotesField
                                    label="Class notes"
                                    content={classDate.notes}
                                    onSave={async (notes) => {
                                        await classDate.updateNotes(notes);

                                        queryClient.invalidateQueries(
                                            classDateQueryKeys.listsInfinite()
                                        );
                                    }}
                                    buttonClassName="h-full"
                                />
                            </div>
                        </div>
                    </div>
                )}
                zeroContent={
                    <ZeroContent
                        icon="CustomClasses"
                        title="No dates planned yet"
                        content={
                            <ScheduleAddEntryDatesButton
                                variant="primary"
                                type="class-date"
                                parentIdentifier={classModel.uuid}
                            />
                        }
                    />
                }
            />
        </>
    );
};

export const PlannedClassDatesList = observer(Component);
