import React, { FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Class } from '@/Models/Class';
import ActionBar from '@/Components/ActionBar';
import ScheduleAddEntryDatesButton from '@/Components/Schedule/AddEntryDatesButton';
import { ClassDate } from '@/Models/ClassDate';
import ClassDateEntryCard from '@/Components/ClassDateEntryCard';
import { TextButton } from '@/Components/Button';
import Card from '@/Components/Card';
import ZeroContent from '@/Components/ZeroContent';
import { inUserZone, toISO } from '@/Services/Date';
import { useListClassDatesQuery } from '@/Features/ClassDates';

interface Props {
    classModel: Class;
}

const Component: FunctionComponent<Props> = ({ classModel }) => {
    const params = useMemo(
        () => ({
            sort: 'start',
            limit: 3,
            filter: {
                class: classModel.uuid,
                'starting-after': toISO(inUserZone()),
            },
        }),
        [classModel.uuid]
    );

    const { isLoading, data } = useListClassDatesQuery(params, {
        staleTime: 0,
    });

    const upcoming = data || [];

    return (
        <Card className="mb-4 flex flex-col" isLoading={isLoading}>
            <ActionBar className="px-app mt-3">
                <ActionBar.Group grow>
                    <h2>Upcoming dates</h2>
                </ActionBar.Group>
                <ActionBar.Group right>
                    <ScheduleAddEntryDatesButton
                        variant="primary"
                        type="class-date"
                        parentIdentifier={classModel.uuid}
                    />
                </ActionBar.Group>
            </ActionBar>

            <div className="px-app py-4">
                {upcoming.length === 0 && (
                    <ZeroContent
                        content={<p>No future dates planned for this class</p>}
                        inline
                    />
                )}
                {upcoming.length > 0 &&
                    upcoming.map((classDate: ClassDate) => (
                        <div key={classDate.uuid} className="mb-2">
                            <ClassDateEntryCard
                                withLink
                                withAttendingCount
                                titleType="datetime"
                                subtitleTypes={['location', 'assignedUsers']}
                                classDate={classDate}
                                className="w-full"
                            />
                        </div>
                    ))}
            </div>

            <footer className="flex justify-center border-t border-gray-200 py-1">
                <TextButton href={`/classes/${classModel.uuid}/planned`}>
                    View all
                </TextButton>
            </footer>
        </Card>
    );
};

export const UpcomingClassDatesList = observer(Component);
