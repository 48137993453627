import { applySnapshot, flow, Instance, types } from 'mobx-state-tree';
import OrganisationTransport from '@/Services/Transport/OrganisationTransport';
import { OrganisationUser } from '@/Models/OrganisationUser';

export interface UserStoreInstance extends Instance<typeof UserStore> {}

export const UserStore = types
    .model('UserStore', {
        users: types.map(OrganisationUser),
    })

    .views((self) => ({
        find(identifier: string) {
            const id = identifier.replace('org-user-', '');
            return self.users.get(`org-user-${id}`);
        },

        findByUserEid(userEid: string) {
            return Array.from(self.users.values()).find(
                (user) => user.userEid === userEid
            );
        },
    }))

    .actions((self) => {
        function createOrUpdate(userData: OrganisationUser) {
            let existing = self.find(userData.identifier);

            if (existing) {
                applySnapshot(existing, {
                    ...existing,
                    ...userData,
                });
            } else {
                self.users.set(userData.identifier, userData);
            }

            return self.find(userData.identifier);
        }

        function processAsNested(user: OrganisationUser) {
            if (!!user.identifier) {
                this.createOrUpdate(user);
                return user.identifier;
            }

            return user;
        }

        return {
            list: flow(function* list() {
                const { data } = yield OrganisationTransport.listUsers();
                const items = data.data.map((userData: OrganisationUser) =>
                    createOrUpdate(userData)
                );

                return items;
            }),

            findOrFetch: flow(function* findOrFetch(identifier: string) {
                let existing = self.find(identifier);

                if (existing) {
                    return existing;
                }

                const { data } = yield OrganisationTransport.fetchUser(
                    identifier.replace('org-user-', '')
                );
                const userData = data.data;

                return createOrUpdate(userData);
            }),

            createOrUpdate,
            processAsNested,
        };
    });
