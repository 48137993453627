import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { useInfiniteQuery } from 'react-query';
import InfiniteItemList from '@/Components/InfiniteItemList';
import ItemListStickyHeader from '@/Components/ItemList/StickyHeader';
import RootStore from '../../../Stores/RootStore';
import SmallCaps from '@/Components/SmallCaps';
import ZeroContent from '@/Components/ZeroContent';
import {
    AgreementListItem,
    CreateAgreementButton,
} from '@/Features/Agreements';
import { AgreementInstance } from '@/Models/Agreement';
import { getNextPageParam } from '@/Services/Api';

interface Props {
    filters?: any;
}

const Component: FunctionComponent<Props> = ({ filters }) => {
    const { agreementStore } = RootStore.stores;

    const queryResult = useInfiniteQuery(
        ['agreements', 'list', filters],
        async ({ pageParam = 1 }) =>
            await agreementStore.list(
                {
                    page: pageParam,
                    ...filters,
                },
                true
            ),
        {
            getNextPageParam,
        }
    );

    return (
        <InfiniteItemList<AgreementInstance>
            queryResult={queryResult}
            renderHeader={
                <ItemListStickyHeader className="xl:grid-cols-agreements-xl">
                    <SmallCaps className="col-span-2">Name</SmallCaps>
                </ItemListStickyHeader>
            }
            renderItem={(agreement, classes) => (
                <AgreementListItem agreement={agreement} className={classes} />
            )}
            zeroContent={
                <ZeroContent
                    inline
                    icon="CustomAgreements"
                    title="No agreements yet"
                    content={
                        <>
                            <p>
                                Create document templates for your clients to
                                read and sign.
                            </p>
                            <CreateAgreementButton />
                        </>
                    }
                />
            }
        />
    );
};

export const AgreementList = observer(Component);
