import { EntityListInfiniteParams, EntityListParams } from '@/Types/Query';
import { WaitlistRegistrationListFilter } from '../Types';

export const keys = {
    all: ['waitlistRegistrations'] as const,

    lists: () => [...keys.all, 'list'] as const,
    listsInfinite: () => [...keys.all, 'listInfinite'] as const,

    list: (params: EntityListParams<WaitlistRegistrationListFilter>) =>
        [...keys.lists(), params] as const,
    listInfinite: (
        params: EntityListInfiniteParams<WaitlistRegistrationListFilter>
    ) => [...keys.listsInfinite(), params] as const,
};
