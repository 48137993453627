import BaseTransport, { paramsSerializer } from './BaseTransport';

class ClassDateTransport extends BaseTransport {
    async list(params) {
        return await this.get('/v3/class-dates', params, {
            paramsSerializer,
        });
    }

    async fetch(uuid, params = {}) {
        return await this.get(`/v3/class-dates/${uuid}`, params);
    }

    async update(uuid, params = {}) {
        return await this.put(`/v3/class-dates/${uuid}`, params);
    }

    async updateNotes(uuid, notes) {
        return await this.put(`/v3/class-dates/${uuid}/notes`, { notes });
    }

    async reschedule(uuid, params = {}) {
        return await this.post(`/v3/class-dates/${uuid}/reschedule`, params);
    }

    async cancel(uuid, params = {}) {
        return await this.post(`/v3/class-dates/${uuid}/cancel`, params);
    }

    async updateDelivery(uuid, data) {
        return await this.put(`/v3/class-dates/${uuid}/delivery`, data);
    }

    // async sendEnrolledNotifications(uuid, data) {
    //     return await this.post(`/v3/class-dates/${uuid}/notify-enrolled`, data);
    // }

    async getEnrolmentCount(uuid, params = {}) {
        return await this.get(
            `/v3/class-dates/${uuid}/enrolment-count`,
            params
        );
    }

    async getClientCommunicationMethodCounts(uuid) {
        return await this.get(
            `/v3/class-dates/${uuid}/client-communication-method-count`
        );
    }

    async listEnrolments(uuid, params = {}) {
        return await this.get(`/v3/class-dates/${uuid}/enrolments`, params);
    }

    async listEnrolled(uuid) {
        return await this.get(`/v3/class-dates/${uuid}/enrolled`);
    }

    async enrol(uuid, params = {}) {
        return await this.post(`/v3/class-dates/${uuid}/enrolments`, params);
    }

    async assignUsers(uuid, data) {
        return await this.post(`/v3/class-dates/${uuid}/assign-users`, data);
    }

    async claim(uuid) {
        return await this.post(`/v3/class-dates/${uuid}/claim`);
    }

    async relinquish(uuid) {
        return await this.post(`/v3/class-dates/${uuid}/relinquish`);
    }

    async getAvailableEnrolmentOptions(uuid, eid) {
        return await this.get(`/v3/class-dates/${uuid}/enrolment-options`, {
            client: eid,
        });
    }

    async listWaitlistRegistrations(uuid, params = {}) {
        return await this.get(
            `/v3/class-dates/${uuid}/waitlist-registrations`,
            params
        );
    }

    async getClientEnrolmentStatuses(uuid, params = {}) {
        return await this.get(
            `/v3/class-dates/${uuid}/enrolment-statuses`,
            params
        );
    }

    async addToWaitlist(uuid, params = {}) {
        return await this.post(
            `/v3/class-dates/${uuid}/waitlist-registrations`,
            params
        );
    }
}

export default new ClassDateTransport();
