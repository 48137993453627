import React, { FunctionComponent, useState } from 'react';
import Modal, { WIDTHS } from '../Modal/index';
import { DestructiveButton, PrimaryButton, TextButton } from '../Button';
import Notification from '../../Services/Notification';
import { StackableModalProps } from '../RootModal';
import RootStore from '../../Stores/RootStore';
import ModalHeader from '@/Components/Modal/Header';

const defaultConfirmText = 'Confirm';
const defaultCancelText = 'No, take me back';

export interface EventConfirmationModalProps extends StackableModalProps {
    content?(): JSX.Element;
    fieldContent?(): JSX.Element;
    isPositive?: boolean;
    isDisabled?: boolean;
    shouldAutoClose?: boolean;
    confirmText?: string | null;
    cancelText?: string | null;
    onCancelled?(): void;
    onConfirmed(): void;
    width?: number;
    title?: string;
}

const ConfirmationModal: FunctionComponent<EventConfirmationModalProps> = ({
    isPositive,
    isDisabled,
    children,
    content,
    fieldContent,
    shouldAutoClose = true,
    confirmText = defaultConfirmText,
    cancelText = defaultCancelText,
    width,
    modalProps,
    ...props
}) => {
    const { uiStateStore } = RootStore.stores;
    const [isConfirmBusy, setIsConfirmBusy] = useState(false);
    const [isCancelBusy, setIsCancelBusy] = useState(false);
    width = width || WIDTHS.narrow;

    const handleAction = async (method: any) => {
        try {
            if (typeof method === 'function') {
                await method();
            }

            if (typeof method === 'undefined' || shouldAutoClose) {
                uiStateStore.popModal();
            } else {
                setIsConfirmBusy(false);
                setIsCancelBusy(false);
            }
        } catch (e) {
            console.log('Confirm error', e);
            Notification.error();
            setIsConfirmBusy(false);
            setIsCancelBusy(false);
        }
    };

    const handleCancel = async () => {
        setIsCancelBusy(true);
        await handleAction(props.onCancelled);
    };

    const handleConfirm = async () => {
        setIsConfirmBusy(true);
        await handleAction(props.onConfirmed);
    };

    console.log(props.title);

    return (
        <Modal
            width={width}
            customContent={true}
            isConfirmation={true}
            stackProps={props.stackProps}
        >
            {props.title && (
                <ModalHeader title={props.title} closable={false} />
            )}

            <div className="dialog__confirmation-content">
                <header>
                    {children}
                    {typeof content === 'function' ? content() : content}
                </header>
                {typeof fieldContent === 'function' && (
                    <section className="dialog__confirmation-fields">
                        {fieldContent()}
                    </section>
                )}
                <footer>
                    <div className="action-bar --filled">
                        {isPositive && (
                            <PrimaryButton
                                onClick={handleConfirm}
                                isLoading={isConfirmBusy}
                                disabled={isDisabled}
                                size="full"
                            >
                                {confirmText}
                            </PrimaryButton>
                        )}
                        {!isPositive && (
                            <DestructiveButton
                                onClick={handleConfirm}
                                isLoading={isConfirmBusy}
                                disabled={isDisabled}
                                size="full"
                            >
                                {confirmText}
                            </DestructiveButton>
                        )}
                    </div>
                    {cancelText && (
                        <div className="dialog__confirmation-cancel">
                            <TextButton
                                onClick={handleCancel}
                                isLoading={isCancelBusy}
                            >
                                {cancelText}
                            </TextButton>
                        </div>
                    )}
                </footer>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
