export const queryKeys = {
    agreements: {
        list: ['agreements', 'list'],
    },
    availableEvents: ['availableEvents'],
    clients: {
        all: ['clients'],
        list: ['clients', 'list'],
        toReview: {
            list: ['clients', 'list', 'toReview'],
        },
    },
    clientInvites: {
        list: ['clientInvites', 'list'],
    },
    dashboard: {
        timeline: (filters: any | undefined = {}) => [
            'dashboard',
            'timeline',
            filters,
        ],
    },
    locations: {
        all: ['locations'],
        list: ['locations', 'list'],
    },
    holidays: {
        all: ['holidays'],
        list: (filters: any) => ['holidays', 'list', filters],
    },
    messages: {
        all: ['messages'],
        list: ['messages', 'list'],
    },
    organisations: {
        all: ['organisations'],
        list: ['organisations', 'list'],
    },
    teamMembers: {
        all: ['teamMembers'],
        list: (filters: any | undefined = {}) => [
            'teamMembers',
            'list',
            filters,
        ],
    },
    sessionPacks: {
        all: ['sessionPacks'],
        view: (id: string, ...params: string[]) => [
            'sessionPacks',
            id,
            ...params,
        ],
    },
    sessions: {
        all: ['sessions'],
    },
    classes: {
        all: ['classes'],
        list: (filters: any | undefined = {}) => ['classes', 'list', filters],
        view: (uuid: string, ...params: string[]) => [
            'sessionPacks',
            uuid,
            ...params,
        ],
    },
    classDates: {
        all: ['classDates'],
        list: (filters: any | undefined = {}) => [
            'classDates',
            'list',
            filters,
        ],
        listAll: ['classDates', 'list'],
        view: (classDateId: string) => ['classDates', classDateId],
        countAfter: (classDateId: string) => [
            'classDates',
            classDateId,
            'countAfter',
        ],
        enrolments: (classDateId: string) => ({
            all: ['classDates', classDateId, 'enrolments'],
            list: (...params: any) => [
                'classDates',
                classDateId,
                'enrolments',
                ...params,
            ],
            count: ['classDates', classDateId, 'enrolments', 'count'],
            clientStatuses: [
                'classDates',
                classDateId,
                'enrolments',
                'clientStatuses',
            ],
        }),
        waitlistRegistrations: (classDateId: string) => ({
            list: ['classDates', classDateId, 'waitlistRegistrations'],
        }),
    },
    enrolments: {
        all: ['enrolments'],
        list: (filters: any | undefined = {}) => [
            'enrolments',
            'list',
            filters,
        ],
        methods: (filters: any | undefined = {}) => [
            'enrolments',
            'methods',
            filters,
        ],
    },
    topDebtors: {
        all: ['topDebtors'],
        list: ['topDebtors', 'list'],
    },
    oldestDebts: {
        all: ['oldestDebts'],
        list: ['oldestDebts', 'list'],
    },
};
