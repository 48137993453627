import React, {
    ComponentPropsWithoutRef,
    FunctionComponent,
    useMemo,
} from 'react';
import { useQuery } from 'react-query';
import { queryKeys } from '@/Services/QueryKeys';
import { Client } from '@/Models/Client';
import { ClassDate } from '@/Models/ClassDate';
import Loader from '@/Components/Loader';
import ClassDateTransport from '@/Services/Transport/ClassDateTransport';
import Alert from '@/Components/Alert';
import SelectableOptionList from '@/Components/SelectableOptionList';
import FormRadioField from '@/Components/FormRadioField';
import FormField from '@/Components/FormField';
import { useFormikContext } from 'formik';
import { EnrolmentFormValues } from '@/Components/ClassDate/EnrolModal';
import FormClassPassSelectField from '@/Components/FormClassPassSelectField';
import Notice from '../../../Components/Notice';
import RootStore from '@/Stores/RootStore';
import FormMembershipSubscriptionSelectField from '@/Components/FormMembershipSubscriptionSelectField';

interface Props extends ComponentPropsWithoutRef<'div'> {
    classDate: ClassDate;
    client: Client;
}

const EnrolmentMethodOptions: FunctionComponent<Props> = ({
    classDate,
    client,
    ...props
}) => {
    const { values, errors, touched } = useFormikContext<EnrolmentFormValues>();
    const { classPassStore, membershipSubscriptionStore } = RootStore.stores;

    const queryData = useQuery(
        queryKeys.enrolments.methods({
            classDate: classDate.uuid,
            client: client.eid,
        }),
        async () => {
            const data = await ClassDateTransport.getAvailableEnrolmentOptions(
                classDate.uuid,
                client.eid
            );

            return {
                classPasses: data.data.classPasses.map(
                    classPassStore.createOrUpdate
                ),
                membershipSubscriptions: data.data.membershipSubscriptions.map(
                    membershipSubscriptionStore.createOrUpdate
                ),
            };
        },
        { staleTime: 0 }
    );

    const hasClassPasses = useMemo(() => {
        return (
            queryData.isSuccess &&
            queryData.data.classPasses &&
            queryData.data.classPasses.length > 0
        );
    }, [queryData.data]);

    const hasMemberships = useMemo(() => {
        return (
            queryData.isSuccess &&
            queryData.data.membershipSubscriptions &&
            queryData.data.membershipSubscriptions.length > 0
        );
    }, [queryData.data]);

    return (
        <>
            <section className="dialog__panel">
                <h2 className="ui-action-bar__title mb-2">Method</h2>
                {queryData.isLoading && <Loader />}

                {queryData.isError && (
                    <Alert
                        type="error"
                        title="There was a problem loading enrolment methods, please try again."
                    />
                )}

                {queryData.isSuccess && (
                    <FormField className="!m-0">
                        <SelectableOptionList horizontal thirds>
                            <FormRadioField
                                name="enrolmentMethod"
                                value="classPass"
                                label={
                                    <span>
                                        <strong>Class pass</strong>
                                        <br />
                                        <span className="font-normal">
                                            Use a valid class pass{' '}
                                            {client.firstName} already has.
                                        </span>
                                    </span>
                                }
                            />
                            <FormRadioField
                                name="enrolmentMethod"
                                value="membership"
                                label={
                                    <span>
                                        <strong>Membership</strong>
                                        <br />
                                        <span className="font-normal">
                                            Use a valid membership{' '}
                                            {client.firstName} already has.
                                        </span>
                                    </span>
                                }
                            />
                            <FormRadioField
                                name="enrolmentMethod"
                                value="single"
                                label={
                                    <span>
                                        <strong>Pay As You Go</strong>
                                        <br />
                                        <span className="font-normal">
                                            {client.firstName} will pay the
                                            single enrolment fee.
                                        </span>
                                    </span>
                                }
                                isDisabled={
                                    !classDate.class.singleEnrolmentEnabled
                                }
                            />
                        </SelectableOptionList>

                        {touched.enrolmentMethod && errors.enrolmentMethod && (
                            <FormField.Error>
                                <p>{errors.enrolmentMethod}</p>
                            </FormField.Error>
                        )}
                    </FormField>
                )}
            </section>

            {values.enrolmentMethod === 'classPass' && (
                <section className="dialog__panel">
                    <h2 className="ui-action-bar__title mb-2">Class pass</h2>

                    <FormField.Row className="m-0">
                        <FormClassPassSelectField
                            isRequired
                            name="classPass"
                            info="Only passes valid for this class are displayed."
                            client={client}
                            explicitOptions={queryData.data?.classPasses ?? []}
                        />
                        <FormField />

                        {!hasClassPasses && (
                            <Notice
                                type="warning"
                                className="col-start-1 col-end-3 mt-2"
                            >
                                {client.firstName} does not have a valid pass
                                for this class.
                            </Notice>
                        )}
                    </FormField.Row>
                </section>
            )}

            {client && values.enrolmentMethod === 'membership' && (
                <section className="dialog__panel">
                    <h2 className="ui-action-bar__title mb-2">Membership</h2>

                    <FormField.Row className="!m-0">
                        <FormMembershipSubscriptionSelectField
                            isRequired
                            name="membership"
                            info="Only memberships valid for this class are displayed."
                            client={client}
                            explicitOptions={
                                queryData.data?.membershipSubscriptions ?? []
                            }
                        />
                        <FormField />

                        {!hasMemberships && (
                            <Notice
                                type="warning"
                                className="col-start-1 col-end-3 mt-2"
                            >
                                {client.firstName} does not have a valid
                                membership for this class.
                            </Notice>
                        )}
                    </FormField.Row>
                </section>
            )}
        </>
    );
};

export default EnrolmentMethodOptions;
