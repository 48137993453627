import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { FormikProps, withFormik } from 'formik';
import { StackableModalProps } from '@/Components/RootModal';
import Notification from '@/Services/Notification';
import { PrimaryButton } from '@/Components/Button';
import Modal from '@/Components/Modal';
import RootStore from '@/Stores/RootStore';
import FormField from '@/Components/FormField';
import { AgreementFormValues } from '@/Features/Agreements';
import queryClient from '@/Modules/QueryClient';
import FormTextField from '@/Domain/Form/FormTextField';
import FormCheckboxField from '@/Components/FormCheckboxField';
import TipTap from '@/Components/TipTap';
import { AxiosError } from 'axios';
import handleServerValidation from '@/Utilities/handleServerValidation';

interface Props extends StackableModalProps {
    isCreating: boolean;
    mapPropsToValues(): AgreementFormValues;
    onSave(values: AgreementFormValues): void;
}

const Component: FunctionComponent<Props & FormikProps<AgreementFormValues>> = (
    props
) => {
    const { values, isCreating, handleSubmit, isSubmitting, setFieldValue } =
        props;

    return (
        <Modal
            stackProps={props.stackProps}
            title={isCreating ? 'New agreement' : 'Edit details'}
            onSubmit={handleSubmit}
            rightAction={() => (
                <PrimaryButton
                    type="submit"
                    isLoading={isSubmitting}
                    data-testid="save-agreement-button"
                >
                    Save
                </PrimaryButton>
            )}
            data-testid="create-update-agreement-modal"
        >
            <section className="dialog__panel">
                <p>
                    Create a new service agreement template for your clients to
                    sign.
                </p>
            </section>

            <section className="dialog__panel">
                <FormField.Row>
                    <FormTextField
                        name="name"
                        label="Name"
                        isRequired
                        info="Name this template for your reference."
                    />
                </FormField.Row>

                <FormField>
                    <FormField.Label>Content</FormField.Label>
                    <TipTap
                        content={values.content}
                        onChange={(content) =>
                            setFieldValue('content', content)
                        }
                    />
                </FormField>

                {!isCreating && (
                    <FormField>
                        <FormCheckboxField
                            name="significantChange"
                            label="This is a significant change and you'll want existing clients to update to this new version."
                        />
                    </FormField>
                )}
            </section>
        </Modal>
    );
};

export const CreateUpdateAgreementModal = withFormik<
    Props,
    AgreementFormValues
>({
    // validationSchema,
    validateOnMount: true,
    mapPropsToValues: (props) => props.mapPropsToValues(),
    handleSubmit: async (values, { props, setErrors }) => {
        try {
            await props.onSave(values);
            await queryClient.invalidateQueries(['agreements', 'list']);
            await queryClient.invalidateQueries([
                'user',
                'currentOrganisationData',
            ]);

            Notification.success(
                `Agreement ${props.isCreating ? 'added' : 'updated'}`
            );
            RootStore.stores.uiStateStore.popModal();
        } catch (e) {
            console.log(e);
            handleServerValidation(e as AxiosError, setErrors);
        }
    },
})(observer(Component));
