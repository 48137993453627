import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { StackableModalProps } from '@/Components/RootModal';
import { Client } from '@/Models/Client';
import { CreateUpdateClientModal } from './CreateUpdateClientModal';
import { ClientFormValues } from '@/Features/Clients';
import { toISODate } from '@/Services/Date';

interface Props extends StackableModalProps {
    client: Client;
    onCreate?(client: Client): void;
}

const Component: FunctionComponent<Props> = (props) => {
    const mapPropsToValues = (): ClientFormValues => {
        const {
            firstName,
            lastName,
            email,
            signedUpAt,
            gender,
            mobilePhone,
            dob,
            address1,
            address2,
            city,
            postCode,
            country,
            timezone,
        } = props.client;

        return {
            firstName,
            lastName,
            email: email ?? '',
            signedUpAt: signedUpAt?.toJSDate(),
            gender: gender ?? '',
            mobilePhone: mobilePhone ?? '',
            dob: dob?.toJSDate(),
            address1: address1 ?? '',
            address2: address2 ?? '',
            city: city ?? '',
            postCode: postCode ?? '',
            country,
            timezone,
        };
    };

    const handleSave = async (values: ClientFormValues) => {
        await props.client.update({
            ...values,
            dob: toISODate(values.dob),
            signedUpAt: toISODate(values.signedUpAt),
        });
    };

    return (
        <CreateUpdateClientModal
            isCreating={false}
            client={props.client}
            stackProps={props.stackProps}
            mapPropsToValues={mapPropsToValues}
            onSave={handleSave}
        />
    );
};

export const UpdateClientModal = observer(Component);
