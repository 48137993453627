import BaseTransport from './BaseTransport';

class AgreementTransport extends BaseTransport {
    async list(params) {
        return await this.get('/v3/agreements', params);
    }

    async fetch(eid, params) {
        return await this.get(`/v3/agreements/${eid}`, params);
    }

    async store(data) {
        return await this.post('/v3/agreements', data);
    }

    async update(eid, input = {}) {
        return await this.put(`/v3/agreements/${eid}`, input);
    }

    async destroy(eid) {
        return await this.delete(`/v3/agreements/${eid}`);
    }
}

export default new AgreementTransport();
