import React, { FunctionComponent } from 'react';
import { TextButton } from '../../Button';
import RootStore from '../../../Stores/RootStore';
import { observer } from 'mobx-react';
import { assetUrl } from '@/Services/String';

interface Props {
    content?: string;
    persist?: boolean;
}

const StripeConnectBanner: FunctionComponent<Props> = ({
    content,
    persist = false,
}) => {
    const { organisation } = RootStore.stores.currentUserStore;

    const text = content
        ? content
        : organisation.locale.supportsStripe && organisation.hasStripeProvider
        ? 'Online payments are enabled.'
        : 'Receive payments from clients online directly into your Striive account.';

    if (
        !organisation.locale.supportsStripe ||
        (organisation.hasStripeProvider && !persist)
    ) {
        return null;
    }

    return (
        <div className="ui-card --rounded mx-0 mb-4 flex flex-col justify-between px-6 pb-2 pt-3 lg:flex-row lg:py-4">
            <div className="flex flex-col items-center text-center lg:flex-row">
                <img
                    src={assetUrl('/images/payments.svg')}
                    alt="Cards"
                    className="my-2"
                />
                <div className="flex flex-col lg:mx-4 lg:text-left">
                    <p className="mb-2 font-bold lg:m-0">Striive Payments</p>
                    <span>{text}</span>
                </div>
            </div>
            <TextButton type="button" href="/organisation/settings/payments">
                More information
            </TextButton>
        </div>
    );
};

export default observer(StripeConnectBanner);
