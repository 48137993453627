import React, { FunctionComponent, useMemo } from 'react';
import { Field } from 'formik';

interface Props {
    name: string;
    value?: string;
    id?: string;
    label: JSX.Element | string;
    isDisabled?: boolean;
    className?: string;
    labelProps?: React.LabelHTMLAttributes<HTMLElement>;
}

const FormCheckboxField: FunctionComponent<Props> = ({
    name,
    value,
    id,
    label,
    isDisabled = false,
    className,
    labelProps,
    ...props
}) => {
    const fieldId = useMemo(() => {
        if (id) {
            return id;
        }

        return value ? `${name}-${value}` : name;
    }, [id, name, value]);

    return (
        <div className={className} {...props}>
            <Field
                type="checkbox"
                name={name}
                value={value}
                className="checkbox"
                id={fieldId}
                disabled={isDisabled}
            />
            <label htmlFor={fieldId} {...labelProps}>
                {label}
            </label>
        </div>
    );
};

export default FormCheckboxField;
