import { Instance, types } from 'mobx-state-tree';
import { ClientReference } from '@/Models/Client';

export interface WaitlistRegistrationInstance
    extends Instance<typeof WaitlistRegistration> {}

export const WaitlistRegistration = types
    .model('WaitlistRegistration', {
        uuid: types.identifier,
        client: types.late(() => ClientReference),
    })

    .views((self) => ({}))

    .actions((self) => {});
