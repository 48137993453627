import {
    applySnapshot,
    flow,
    getRoot,
    getSnapshot,
    types,
} from 'mobx-state-tree';
import { DateTimeType } from '../Types/DateTime';
import WithLegacyDates from './WithLegacyDates';
import { LocationReference } from './Location';
import ScheduleEntry from './ScheduleEntry';
import RootStore from '../Stores/RootStore';
import ClassDateTransport from '../Services/Transport/ClassDateTransport';
import WithLocation from './WithLocation';
import { deliveryFromFormValues } from '../Services/Delivery';
import { EmployerReference } from './Employer';
import { Enrolment } from './Enrolment';
import { ClassReference } from '../Stores/ClassStore';
import ClassEmployedBase from './ClassEmployedBase';
import ClassPrivateBase from './ClassPrivateBase';
import { OrganisationUser } from './OrganisationUser';
import { Currency } from './Currency';
import { classCostString } from '../Services/Classes';
import { WaitlistRegistration } from './WaitlistRegistration';

const ClassDateModel = types
    .model('ClassDate', {
        uuid: types.string,
        identifier: types.identifier, // For compatibility with non uuid entities
        type: types.literal('class-date'),
        resourceVersion: 2,
        name: '',
        start: DateTimeType,
        end: DateTimeType,
        length: types.integer,
        notes: types.maybeNull(types.string),

        classType: types.string,

        // Employed
        fee: types.maybe(types.integer),
        estimatedFee: types.maybe(types.integer),
        enrolmentLink: types.maybe(types.maybeNull(types.string)),
        confirmedAt: types.maybe(types.maybeNull(DateTimeType)),

        // Private
        hasLimitedSpaces: false,
        maxEnrolments: types.maybe(types.maybeNull(types.integer)),
        enrolmentCost: types.maybe(types.maybeNull(types.integer)),
        payableAtLocation: types.maybe(types.boolean),
        cancellationPolicy: types.maybe(types.integer),

        class: types.late(() => ClassReference),

        employer: types.maybeNull(EmployerReference),
        location: types.maybeNull(LocationReference),
        enrolments: types.array(
            types.late(() => types.safeReference(Enrolment))
        ),
        assignedUsers: types.array(types.reference(OrganisationUser)),
        isAvailableToAssign: types.maybe(types.boolean),
        references: types.model({
            class: '',
            location: types.maybeNull(types.string),
        }),

        totalAttending: types.integer,
        totalForfeited: types.integer,
        totalOnWaitlist: types.integer,
        availableSpaces: types.maybeNull(types.integer),

        busy: false,
        isNew: false,
        newCardId: types.maybe(types.string),
        currency: Currency,

        waitlistRegistrations: types.maybe(types.array(WaitlistRegistration)),
    })

    .preProcessSnapshot((snapshot) => {
        const sn = {
            ...snapshot,
        };

        if (
            typeof snapshot.enrolments !== 'undefined' &&
            !Array.isArray(snapshot.enrolments)
        ) {
            sn.enrolments = [];
        }

        if (typeof snapshot.class === 'object') {
            RootStore.stores.classStore.createOrUpdate(snapshot.class);
            sn.class = snapshot.class.uuid;
        }

        return sn;
    })

    .views((self) => ({
        get scheduleId() {
            return `class-date-${self.uuid}`;
        },

        /**
         * Determine if the class is an employed one
         * @returns {boolean}
         */
        get isEmployed() {
            return self.classType === 'employed';
        },

        /**
         * Determine if the class is a private one
         * @returns {boolean}
         */
        get isPrivate() {
            return self.classType === 'private';
        },

        get scheduleResourceData() {
            return {
                id: self.uuid,
                type: 'class-date',
                start_at: self.start,
                end_at: self.end,
                locationName: self.hasLocation ? self.locationName : null,
                label: self.label,
            };
        },

        get mapDataToFormValues() {
            return {
                ...getSnapshot(self),
                ...self.deliveryFormValues,
                employer: self.employer?.uuid ?? '',
                enrolmentLink: self.enrolmentLink ?? '',
                assignedUsers: self.assignedUsers?.[0]?.uuid ?? '',
            };
        },

        /**
         * Helper for displaying on the schedule
         * @returns {*}
         */
        get label() {
            return self.class.name;
        },

        /**
         * Determine if the date has been confirmed
         * @returns {boolean}
         */
        get isConfirmed() {
            return !!self.confirmedAt;
        },

        /**
         * Calculate the hourly earning for the date
         */
        get hourlyEarnings() {
            if (!self.fee) {
                return 0;
            }

            return (self.fee / self.length) * 4;
        },

        get hasEnrolments() {
            return (self.totalAttending || 0) > 0;
        },

        get isFull() {
            return self.hasLimitedSpaces && self.availableSpaces <= 0;
        },

        get sortedEnrolments() {
            return self.enrolments.sort((a, b) =>
                a.client?.fullName < b.client?.fullName ? -1 : 1
            );
        },

        /**
         * Only return enrolments that are attending
         * @returns {*}
         */
        get attendingEnrolments() {
            return self.sortedEnrolments.filter(
                (enrolment) => !enrolment.hasAttendance || enrolment.wasAttended
            );
        },

        /**
         * Only return enrolments that are attending
         * @returns {*}
         */
        get forfeitedEnrolments() {
            return self.sortedEnrolments.filter(
                (enrolment) => enrolment.hasAttendance && enrolment.wasForfeited
            );
        },

        get singleEnrolmentEnabled() {
            return (
                typeof self.enrolmentCost !== 'undefined' &&
                self.enrolmentCost !== null
            );
        },

        get costString() {
            return classCostString(self);
        },
    }))

    .actions((self) => ({
        update: flow(function* update(input = {}) {
            self.busy = true;

            const { data } = yield ClassDateTransport.update(self.uuid, {
                ...getSnapshot(self),
                ...input,
            });

            self.updateData({
                ...data.data,
                busy: false,
            });

            return {
                classDate: self,
                otherDates:
                    data.dates?.map((date) =>
                        RootStore.stores.entryStore.createOrUpdate(date)
                    ) ?? [],
            };
        }),

        updateNotes: flow(function* updateNotes(notes = null) {
            const { data } = yield ClassDateTransport.updateNotes(
                self.uuid,
                notes
            );

            return self.updateData(data.data);
        }),

        assignUsers: flow(function* assignUsers(
            assignedUsers = [],
            isAvailableToAssign
        ) {
            const { data } = yield ClassDateTransport.assignUsers(self.uuid, {
                assignedUsers,
                isAvailableToAssign,
            });

            return self.updateData(data.data);
        }),

        listEnrolments: flow(function* listEnrolments(
            params = {},
            withMeta = false
        ) {
            const { data } = yield ClassDateTransport.listEnrolments(
                self.uuid,
                params
            );

            const items = data.data.map((enrolmentData) => {
                const enrolment =
                    getRoot(self).stores.enrolmentStore.createOrUpdate(
                        enrolmentData
                    );
                self.createEnrolmentReference(enrolment);

                return enrolment;
            });

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        cancel: flow(function* cancel(params = {}) {
            const { data } = yield ClassDateTransport.cancel(self.uuid, params);

            if (typeof data.classDate !== 'undefined') {
                self.updateData(data.classDate);
            }
            if (typeof data.credited !== 'undefined') {
                getRoot(self).stores.enrolmentStore.setIssuedCredit(
                    data.credited
                );
            }

            return self;
        }),

        createEnrolmentReference(enrolment) {
            const existing = self.enrolments.find(
                (enr) => enr.uuid === enrolment.uuid
            );

            if (!existing) {
                self.enrolments.push(enrolment.uuid);
            }
        },

        updateData(data = {}) {
            data.assignedUsers =
                data.assignedUsers?.map((user) => user.identifier || user) ||
                self.assignedUsers;

            applySnapshot(self, {
                ...getSnapshot(self),
                ...data,
            });

            return self;
        },

        setFormData(values) {
            self.updateData(values);
            self.updateDelivery(deliveryFromFormValues(values));
        },

        /**
         * Enrolments
         */

        enrol: flow(function* enrol(input = {}) {
            const { data, ...response } = yield ClassDateTransport.enrol(
                self.uuid,
                input
            );

            if (response.status === 204) {
                return;
            }

            const enrolment = getRoot(
                self
            ).stores.enrolmentStore.createOrUpdate(data.data);

            self.createEnrolmentReference(enrolment);
        }),

        addToWaitlist: flow(function* addToWaitlist(input = {}) {
            yield ClassDateTransport.addToWaitlist(self.uuid, input);
        }),

        listInfiniteWaitlistRegistrations: flow(
            function* listInfiniteWaitlistRegistrations(
                params = {},
                withMeta = false
            ) {
                const { data } =
                    yield ClassDateTransport.listWaitlistRegistrations(
                        self.uuid,
                        params
                    );

                const items = data.data.map((item) =>
                    self.createOrUpdateWaitlistRegistration(item)
                );

                return withMeta ? { data: items, meta: data.meta } : items;
            }
        ),

        createOrUpdateWaitlistRegistration(data) {
            const hasWaitlistRegistrations = Array.isArray(
                self.waitlistRegistrations
            );

            if (!hasWaitlistRegistrations) {
                self.waitlistRegistrations = [];
            }

            const existing = self.waitlistRegistrations.find(
                (item) => item.uuid === data.uuid
            );

            if (existing) {
                existing.updateData(data);
            } else {
                self.waitlistRegistrations = [
                    ...self.waitlistRegistrations,
                    WaitlistRegistration.create(data),
                ];
            }
        },
    }));

export const ClassDate = types.compose(
    ClassDateModel,
    ClassEmployedBase,
    ClassPrivateBase,
    ScheduleEntry,
    WithLegacyDates,
    WithLocation
);

export const ClassDateReference = types.safeReference(ClassDate, {
    get(uuid, parent) {
        return getRoot(parent).stores.entryStore.find(uuid, 'class-date');
    },
    set({ uuid }) {
        return uuid;
    },
});
