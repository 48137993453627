import { Client } from '@/Models/Client';
import { getOrdinal, inUserZone } from '@/Services/Date';
import { countInflect, possessive } from '@/Services/String';
import { DateTime } from 'luxon';
import { yearsAsClient } from '.';
import { ClientModel } from '../Types';

export const isClientBirthday = (client: Client | ClientModel) => {
    if (!client.dob) {
        return false;
    }

    return (
        DateTime.fromJSDate(client.dob, { zone: client.timezone }).toFormat(
            'MM-dd'
        ) === inUserZone(null, false)!.toFormat('MM-dd')
    );
};

export const isClientAnniversary = (client: Client | ClientModel) => {
    if (!client.signedUpAt) {
        return false;
    }

    const isToday =
        DateTime.fromJSDate(client.signedUpAt, {
            zone: client.timezone,
        }).toFormat('MM-dd') === inUserZone(null, false)!.toFormat('MM-dd');

    return isToday && yearsAsClient(client)! >= 1;
};

export const isClientOccasion = (client: Client | ClientModel) => {
    return isClientBirthday(client) || isClientAnniversary(client);
};

export const clientBirthdayString = (client: Client, includeEmoji = true) => {
    let string = includeEmoji ? '🎂  ' : '';

    return (
        string +
        `${possessive(client.firstName)} ${client.age}${getOrdinal(
            client.age
        )} birthday!`
    );
};

export const clientAnniversaryString = (
    client: Client,
    includeEmoji = true
) => {
    let string = includeEmoji ? '🎉  ' : '';

    return (
        string +
        `Client for ${countInflect(client.yearsAsClient, 'year', 'years')}!`
    );
};
