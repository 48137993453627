import React, { FunctionComponent, useContext } from 'react';
import { observer } from 'mobx-react';
import { ClassPass } from '@/Models/ClassPass';
import { ClearButton } from '@/Components/Button';
import { uiColors } from '@/Theme';
import EventConfirmationModalContext from '@/Contexts/EventConfirmationModalContext';
import Notification from '@/Services/Notification';
import queryClient from '@/Modules/QueryClient';
import { useHistory } from 'react-router-dom';

interface Props {
    classPass: ClassPass;
    onUpdate?(classPass: ClassPass): void;
}

const Component: FunctionComponent<Props> = ({ classPass }) => {
    const confirmationContext = useContext(EventConfirmationModalContext);
    const history = useHistory();

    async function deleteClassPass() {
        try {
            await classPass.destroy();
            await queryClient.invalidateQueries(['classPasses', 'purchases']);

            Notification.success('Class pass deleted');

            history.push('/class-passes/purchases');
        } catch (e) {
            console.log(e);
            Notification.error();
        }
    }

    function handleClick() {
        confirmationContext.requireConfirmation({
            content: () => (
                <p className="mb-2">
                    Are you sure you want to delete this class pass?
                </p>
            ),
            onConfirmed: deleteClassPass,
        });
    }

    if (classPass.hasEnrolments) {
        return null;
    }

    return (
        <ClearButton
            onClick={handleClick}
            icon="Delete"
            fill={uiColors.negative.default}
        >
            Delete
        </ClearButton>
    );
};

export const DeleteClassPassButton = observer(Component);
