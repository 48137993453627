import React, { FunctionComponent, useState } from 'react';
import {
    ButtonProps,
    PrimaryButton,
    PrimaryResponsiveButton,
    WhiteButton,
} from '@/Components/Button';
import Notification from '../../../Services/Notification';
import { observer } from 'mobx-react';
import RootStore from '../../../Stores/RootStore';
import { Client } from '@/Models/Client';
import { ClassPass } from '@/Models/ClassPass';
import Loader from '@/Components/Loader';
import useAuth from '@/Hooks/useAuth';

interface Props {
    client?: Client;
    isResponsive?: boolean;
    variant?: 'primary' | 'inline' | 'selectable';
    className?: string;
    removeBeneath?: boolean;
    onSuccess?(classPass: ClassPass): void;
    navigateOnSuccess?: boolean;
}

const Component: FunctionComponent<Props> = ({
    client,
    variant = 'primary',
    className,
    onSuccess,
    navigateOnSuccess = true,
    isResponsive = false,
    removeBeneath = false,
}) => {
    const { uiStateStore, packageStore } = RootStore.stores;
    const [isBusy, setIsBusy] = useState(false);

    async function handleClick() {
        setIsBusy(true);

        try {
            await packageStore.list({ type: 'class' });
            uiStateStore.pushModal(
                'CLASS_PASS_ASSIGN',
                { client, onSuccess, navigateOnSuccess },
                removeBeneath
            );
        } catch (e) {
            Notification.error();
        }

        if (!removeBeneath) {
            setIsBusy(false);
        }
    }

    const buttonProps: ButtonProps = {
        type: 'button',
        icon: 'Plus',
        isLoading: isBusy,
        onClick: handleClick,
        className,
    };

    const { hasRole } = useAuth();

    if (variant === 'inline') {
        if (hasRole('Owner')) {
            return <WhiteButton {...buttonProps}>New</WhiteButton>;
        }

        return null;
    }

    if (variant === 'selectable') {
        return (
            <a
                onClick={handleClick}
                className="button --selectable-option --flash-class"
            >
                {isBusy && <Loader withShroud withPosition />}
                <>
                    <span className="button__title">Class passes</span>
                    <span className="button__sub-title">
                        Record sale or send a purchase link
                    </span>
                </>
            </a>
        );
    }

    return isResponsive ? (
        <PrimaryResponsiveButton {...buttonProps}>
            Sell pass
        </PrimaryResponsiveButton>
    ) : (
        <PrimaryButton {...buttonProps}>Sell pass</PrimaryButton>
    );
};

export const AssignClassPassButton = observer(Component);
