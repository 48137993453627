import React, { FunctionComponent } from 'react';
import RootStore from '@/Stores/RootStore';
import { ClassDate } from '@/Models/ClassDate';
import { observer } from 'mobx-react';
import { Icon } from '@mdi/react';
import { mdiAccountClock } from '@mdi/js';
import { Button, ClearButton } from '@/Components/Button';

interface Props {
    classDate: ClassDate;
    variant?: 'default' | 'menu';
}

const Component: FunctionComponent<Props> = ({
    classDate,
    variant = 'default',
}) => {
    const handleClick = () => {
        RootStore.stores.uiStateStore.pushModal('CLASS_DATE_ADD_TO_WAITLIST', {
            classDate,
        });
    };

    const buttonProps = {
        icon: (
            <Icon
                path={mdiAccountClock}
                size={0.8}
                className="-ml-0.5 mr-1.5 fill-current text-icon"
            />
        ),
        onClick: handleClick,
    };

    if (variant === 'menu') {
        return <ClearButton {...buttonProps}>Add to waitlist</ClearButton>;
    }

    return <Button {...buttonProps} />;
};

export const CreateWaitlistRegistrationButton = observer(Component);
