import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { ClassPass } from '../../Models/ClassPass';
import FormSelectField, { FormSelectFieldProps } from '../FormSelectField';
import { OptionTypeBase } from 'react-select/src/types';
import RootStore from '../../Stores/RootStore';
import { Client } from '../../Models/Client';
import { useQuery } from 'react-query';
import { useField, useFormikContext } from 'formik';
import { AssignClassPassButton } from '@/Features/ClassPasses';
import queryClient from '@/Modules/QueryClient';

interface ClassPassFilters {
    class?: string;
    status?: 'active' | 'usable';
    withAttributes?: string[];
    'expires-after'?: string;
}

interface Props extends FormSelectFieldProps {
    client: Client;
    explicitOptions: ClassPass[];
    filters?: ClassPassFilters;
}

const CustomOption: FunctionComponent = (option: OptionTypeBase) => {
    const { classPassStore } = RootStore.stores;
    const classPass = classPassStore.find(option.value);

    return (
        <div>
            <span className="mr-2 inline-block">{option.label}</span>
            <span className="my-2 inline-block h-6 whitespace-nowrap rounded-full border border-gray-200 px-2 pt-1 text-sm leading-3">
                {classPass?.enrolmentLimitEnabled
                    ? classPass.remainingCredits
                    : '∞'}{' '}
                left
            </span>
        </div>
    );
};

const FormClassPassSelectField: FunctionComponent<Props> = ({
    client,
    filters,
    explicitOptions,
    ...props
}) => {
    const { classPassStore } = RootStore.stores;
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props.name);
    const allFilters = {
        client: client.eid,
        ...(filters ?? {}),
    };
    const { data, isLoading } = useQuery(
        ['classPasses', 'purchases', allFilters],
        async () => await classPassStore.list(allFilters),
        { enabled: typeof explicitOptions === 'undefined' }
    );

    useEffect(() => {
        queryClient.invalidateQueries([
            'classPasses',
            'purchases',
            { client: client.eid },
        ]);
    }, [client]);

    const options = useMemo(() => {
        const availableOptions = explicitOptions ? explicitOptions : data;

        return (availableOptions ?? []).map((classPass: ClassPass) => ({
            value: classPass.uuid,
            label: classPass.name,
        }));
    }, [data, explicitOptions]);

    useEffect(() => {
        if (!options.length) {
            setFieldValue(props.name, undefined);
        } else if (options.length && !field.value) {
            setFieldValue(props.name, options[0].value);
        }
    }, [options]);

    const handleCreate = (classPass: ClassPass) => {
        setFieldValue(props.name, classPass.uuid);
    };

    return (
        <FormSelectField
            options={options}
            fieldAccessoryAfter={
                <AssignClassPassButton
                    client={client}
                    className="ml-2"
                    variant="inline"
                    onSuccess={handleCreate}
                    navigateOnSuccess={false}
                />
            }
            {...props}
            fieldProps={{
                formatOptionLabel: CustomOption,
                isDisabled: isLoading || options.length === 0,
            }}
        />
    );
};

export default FormClassPassSelectField;
