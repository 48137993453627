import { ageInYears } from '@/Services/Date';
import { DateTime } from 'luxon';
import { Client } from '../../../Models/Client';
import { ClientModel } from '../Types';

export const clientUrl = (client: Client | ClientModel, tab?: string) => {
    let url = `/clients/roster/${client.eid}`;

    if (tab) {
        url += `/${tab}`;
    }

    return url;
};

export const yearsAsClient = (client: Client | ClientModel) => {
    if (!client.signedUpAt) {
        return null;
    }

    return ageInYears(
        DateTime.fromJSDate(client.signedUpAt, { zone: client.timezone })
    );
};
