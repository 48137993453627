import { WaitlistRegistrationListFilter } from '@/Features/WaitlistRegistrations/Types';
import { WaitlistRegistration } from '@/Models/WaitlistRegistration';
import { EntityListInfiniteParams } from '@/Types/Query';
import { applySnapshot, flow, Instance, types } from 'mobx-state-tree';

export interface WaitlistRegistrationStoreInstance
    extends Instance<typeof WaitlistRegistrationStore> {}

interface ClientApiData {}

interface WaitlistRegistrationApiData {
    uuid: string;
    client?: ClientApiData;
}

export const WaitlistRegistrationStore = types
    .model('WaitlistRegistrationStore', {
        registrations: types.map(WaitlistRegistration),
    })

    .views((self) => ({
        find(identifier: string) {
            return self.registrations.get(identifier);
        },
    }))

    .actions((self) => {
        function createOrUpdate(
            registrationApiData: WaitlistRegistrationApiData
        ) {
            let existing = self.find(registrationApiData.uuid);

            if (existing) {
                applySnapshot(existing, {
                    ...existing,
                    ...registrationApiData,
                });
            } else {
                self.registrations.set(
                    registrationApiData.uuid,
                    registrationApiData
                );
            }

            return self.find(registrationApiData.uuid);
        }

        function processAsNested(
            registrationApiData: WaitlistRegistrationApiData | string
        ) {
            if (
                typeof registrationApiData === 'string' ||
                !registrationApiData
            ) {
                return registrationApiData;
            }

            this.createOrUpdate(registrationApiData);

            return registrationApiData.uuid;
        }

        return {
            list: flow(function* list(
                params: EntityListInfiniteParams<WaitlistRegistrationListFilter>,
                withMeta = false
            ) {
                // const { data } =
                //     yield OrganisationTransport.listWaitlistRegistrations();
                // const items = data.data.map((userData: WaitlistRegistration) =>
                //     createOrUpdate(userData)
                // );
                //
                // return items;
            }),

            findOrFetch: flow(function* findOrFetch(identifier: string) {
                // let existing = self.find(identifier);
                //
                // if (existing) {
                //     return existing;
                // }
                //
                // const { data } =
                //     yield OrganisationTransport.fetchWaitlistRegistration(
                //         identifier.replace('org-user-', '')
                //     );
                // const userData = data.data;
                //
                // return createOrUpdate(userData);
            }),

            createOrUpdate,
            processAsNested,
        };
    });
